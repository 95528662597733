import React, { useState } from "react"
import { Transition } from "@headlessui/react"
import { useQuery, useMutation } from "urql"
import { getUser } from "services/auth"
import UpdateAddress from "components/layouts/Account/UpdateAddress"
import UpdateProfile from "components/layouts/Account/UpdateProfile"
import VipTier from "components/layouts/Account/VipTier"
import { AccountDetailsPlaceholder } from "components/elements/Placeholders"
import { NotificationContext } from "context/notification-context"

const DeleteAddressMutation = `
  mutation customerAddressDelete($customerAccessToken: String!, $addressId: ID!) {
    customerAddressDelete(
      customerAccessToken: $customerAccessToken
      id: $addressId
    ) {
      deletedCustomerAddressId
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

const UpdateDefaultAddressMutation = `
  mutation customerDefaultAddressUpdate($customerAccessToken: String!, $addressId: ID!) {
    customerDefaultAddressUpdate(
      customerAccessToken: $customerAccessToken
      addressId: $addressId
    ) {
      customer {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

const ProfileQuery = `
  query ($customerAccessToken: String!) {
    customer (customerAccessToken: $customerAccessToken) {
        id
        firstName
        lastName
        email
        phone
        acceptsMarketing
        defaultAddress {
          id
          lastName
          firstName
          address1
          address2
          company
          province
          country
          zip
          city
          phone
        }
        addresses (first: 15) {
          edges {
            cursor
            node {
              id
              lastName
              firstName
              address1
              address2
              company
              province
              country
              zip
              city
              phone
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        orders (first: 10) {
          edges {
              node {
                  customerLocale 
                  currentTotalPrice {
                      amount
                      currencyCode
                  }
              }
          } 
        }
      }
    }
  `

const Profile = () => {
  const user = getUser()
  const [addressToUpdate, setAddressToUpdate] = useState({})
  const [showAddressForm, setShowAddressForm] = useState(false)
  const [showProfileForm, setShowProfileForm] = useState(false)
  const { sendNotification } = React.useContext(NotificationContext)

  const handleEditAddress = (address) => {
    setAddressToUpdate(address)
    setShowAddressForm(true)
  }

  const handleAddNewAddress = () => {
    setAddressToUpdate({})
    setShowAddressForm(true)
  }

  const [, updateDefaultAddress] = useMutation(UpdateDefaultAddressMutation)
  const handleUpdateDefaultAddress = async (id) => {
    const result = await updateDefaultAddress({
      customerAccessToken: user.accessToken,
      addressId: id,
    })

    const { data, error } = result
    if (data?.customerDefaultAddressUpdate?.customer) {
      sendNotification({
        type: "success",
        messages: ["Your primary address was updated successfully!"],
      })
    }
    if (error) {
      sendNotification({
        type: "error",
        messages: [...error.graphQLErrors],
      })
    }
  }

  const [, deleteAddress] = useMutation(DeleteAddressMutation)
  const handleDeleteAddress = async (id) => {
    const result = await deleteAddress({
      customerAccessToken: user.accessToken,
      addressId: id,
    })
    // TODO page should refresh on success
    const { data, error } = result
    if (data?.customerAddressDelete?.deletedCustomerAddressId) {
      sendNotification({
        type: "success",
        messages: ["Address successfully deleted!"],
      })
      reexecuteProfileQuery({ requestPolicy: "network-only" })
    }
    if (error) {
      sendNotification({
        type: "error",
        messages: [...error.graphQLErrors],
      })
    }
  }

  const [profileQueryResult, reexecuteProfileQuery] = useQuery({
    query: ProfileQuery,
    variables: {
      customerAccessToken: user.accessToken,
    },
  })
  const { data, fetching } = profileQueryResult

  if (fetching) {
    return <AccountDetailsPlaceholder loading={fetching} />
  }

  if (data) {
    const addresses = data?.customer?.addresses?.edges ?? []

    return (
      <>
        <h1 className="font-bold text-idc-title text-2xl lg:text-[1.625rem] mb-6">
          Account Details
        </h1>

        <div className="flex justify-between lg:justify-start items-baseline mb-6">
          <h2 className="font-bold text-idc-title text-[1.375rem] lg:text-2xl mr-4">
            Shipping Addresses
          </h2>
          <button
            onClick={() => handleAddNewAddress()}
            className="font-semibold text-idc-blue text-sm lg:text-base underline hover:text-[#F47761] active:text-idc-orange transition duration-300 ease-in-out"
          >
            Add New Address
          </button>
        </div>
        <ul className="text-idc-body text-[1.0625rem] mb-[4.5rem]">
          {addresses.map(({ node: address }) => {
            const isPrimaryAddress =
              address.id === data.customer?.defaultAddress?.id

            return (
              <li
                key={address.id}
                className="flex flex-col lg:flex-row justify-between items-start border-t first:border-t-0 py-6 first:pt-0 last:pb-0"
              >
                <div>
                  <p>
                    {address.firstName} {address.lastName}
                  </p>
                  <p>{address.address1}</p>
                  <p>
                    {address.city}, {address.zip}
                  </p>
                  <p>{address.country}</p>
                </div>

                <div className="flex justify-end gap-6 w-full lg:max-w-[18.75rem] mt-2 lg:mt-0 ">
                  {isPrimaryAddress ? (
                    <p className="text-xs lg:text-base font-semibold text-idc-orange">
                      Primary Address
                    </p>
                  ) : (
                    <button
                      onClick={() => handleUpdateDefaultAddress(address.id)}
                      className="text-xs lg:text-base font-semibold text-idc-blue underline hover:text-[#F47761] active:text-idc-orange transition duration-300 ease-in-out"
                    >
                      Set as Primary
                    </button>
                  )}
                  <button
                    onClick={() => handleEditAddress(address)}
                    className="text-xs lg:text-base font-semibold text-idc-blue underline hover:text-[#F47761] active:text-idc-orange transition duration-300 ease-in-out"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteAddress(address.id)}
                    className="text-xs lg:text-base font-semibold text-idc-blue underline hover:text-[#F47761] active:text-idc-orange transition duration-300 ease-in-out"
                  >
                    Delete
                  </button>
                </div>
              </li>
            )
          })}
        </ul>

        <Transition
          show={showAddressForm}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <UpdateAddress
            addressToUpdate={addressToUpdate}
            setShowAddressForm={setShowAddressForm}
          />
        </Transition>

        <div className="flex justify-between items-baseline mt-[4.5rem]">
          <h2 className="font-bold text-idc-title text-[1.375rem] lg:text-2xl mr-4">
            Personal Information
          </h2>
          <button
            onClick={() => setShowProfileForm(true)}
            className="font-semibold text-idc-blue text-sm lg:text-base underline hover:text-[#F47761] active:text-idc-orange transition duration-300 ease-in-out"
          >
            Edit
          </button>
        </div>
        <ul className="text-idc-body">
          <li>
            {data.customer.firstName} {data.customer.lastName}
          </li>
          <li>{data.customer.email}</li>
          <li>{data.customer.phone}</li>
        </ul>

        <Transition
          show={showProfileForm}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <UpdateProfile
            profileToUpdate={data.customer}
            setShowProfileForm={setShowProfileForm}
            reexecuteProfileQuery={reexecuteProfileQuery}
          />
        </Transition>
        <VipTier />
      </>
    )
  }
}

export default Profile
