import React, { useEffect } from "react"
import { loadScript, CustomerIdentificationDiv } from "utils/yotpoLoyalty"

const VipTier = () => {
  useEffect(() => {
    loadScript()
  }, [])

  return (
    <>
      <CustomerIdentificationDiv />
      <div key="yotpo-widget" className="-mx-4 md:-mx-6 lg:-mx-10">
        <div
          className="yotpo-widget-instance"
          data-yotpo-instance-id="222895"
        ></div>
      </div>
    </>
  )
}

export default VipTier
