import React from "react"

const Container = ({ children }: { children: any }) => {
  return (
    <div className="mx-4 md:mx-6 lg:mx-10">
      <div className="w-full mx-auto max-w-7xl">{children}</div>
    </div>
  )
}

export const FormContainer = ({ children }: { children: any }) => {
  return <div className="lg:max-w-[32rem] lg:mx-auto">{children}</div>
}

export default Container
