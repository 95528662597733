import React from "react"
import { getUser } from "services/auth"

const parseCustomerId = (user) => {
  let customerId = ""
  let buff
  const isBrowser = typeof window !== "undefined"

  if (user?.id) {
    buff = user.id
    customerId = buff.replace("gid://shopify/Customer/", "")
  }

  return customerId
}

export const loadScript = () => {
  const isBrowser = typeof window !== "undefined"

  if (isBrowser) {
    const script = document.createElement("script")
    script.src = `https://cdn-widgetsrepository.yotpo.com/v1/loader/Yir-Wf3wNt8_YqON7EvShA`
    script.async = true
    script.id = "yotpo-module"
    document.body.appendChild(script)

    if (script.readyState) {
      // only required for IE <9
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null
          if (window?.yotpoWidgetsContainer?.initWidgets)
            window.yotpoWidgetsContainer.initWidgets()
        }
      }
    } else {
      //Others
      script.onload = function () {
        if (window?.yotpoWidgetsContainer?.initWidgets)
          window.yotpoWidgetsContainer.initWidgets()
      }
    }
  }
}

export const CustomerIdentificationDiv = () => {
  const user = getUser()

  if (!user?.email || !user?.id) {
    return (
      <div
        id="swell-customer-identification"
        data-authenticated="false"
        style={{ display: "none" }}
      ></div>
    )
  }

  const customerId = parseCustomerId(user)
  const customerTags = []
  return (
    <div
      id="swell-customer-identification"
      data-authenticated="true"
      data-email={user.email}
      data-id={customerId}
      data-tags={JSON.stringify(customerTags)}
      style={{ display: "none" }}
    ></div>
  )
}

export const getActiveCampaign = async () => {
  const user = getUser()
  const options = { method: "POST" }

  if (user.email) {
    options.body = JSON.stringify({
      email: user.email,
    })
  }

  try {
    const result = await fetch("/api/loyalty/active-campaigns", options).then(
      (res) => res.json()
    )

    return result
  } catch (err) {
    // console.warn(err)
    return []
  }
}
