import React, { useState } from "react"
import { navigate, Link } from "gatsby"
import { useMutation } from "urql"
import { isLoggedIn } from "services/auth"
import queryString from "query-string"
import { NotificationContext } from "context/notification-context"
import { FormContainer } from "components/elements/Container"

const ResetPasswordQuery = `
mutation customerResetByUrl($resetUrl: URL!, $password: String!) {
  customerResetByUrl(resetUrl: $resetUrl, password: $password) {
    customer {
      id
    }
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
`

const ResetPassword = ({ location }) => {
  const [customer, setCustomer]: [
    { newPassword: string; confirmNewPassword: string },
    any
  ] = useState({ newPassword: "", confirmNewPassword: "" })
  const [errors, setErrors]: [object[], any] = useState({})
  const qString = queryString.parse(location.search)
  const resetUrl = qString?.reset_url ? qString?.reset_url : ""
  const { sendNotification } = React.useContext(NotificationContext)

  const handleInputChange = (e) => {
    setErrors({})
    setCustomer({
      ...customer,
      [e.target.name]: e.target.value.trim(),
    })
  }

  const [resetPasswordResult, resetPassword] = useMutation(ResetPasswordQuery)

  const handleSubmit = async (e) => {
    //https://formidable.com/open-source/urql/docs/basics/react-preact/#mutations
    e.preventDefault()
    const formValid = validateForm()

    if (formValid) {
      resetPassword({
        resetUrl: resetUrl,
        password: customer.newPassword,
      }).then((result) => {
        const { data, error } = result
        if (data?.customerResetByUrl?.customer) {
          sendNotification({
            type: "success",
            messages: ["Your password was reset successfully!"],
          })
          navigate("/account/login/")
        }
        if (data?.customerResetByUrl?.customerUserErrors.length > 0) {
          sendNotification({
            type: "error",
            messages: data?.customerResetByUrl?.customerUserErrors.map(
              (e) => e.message
            ),
          })
        }
        if (error) {
          sendNotification({
            type: "error",
            messages: error.graphQLErrors.map((e) => e.message),
          })
        }
      })
    }
  }

  const validateForm = () => {
    const formErrors = {}
    // password validation
    switch (true) {
      case customer.newPassword.trim() === "" ||
        customer.confirmNewPassword.trim() === "":
        formErrors.newPassword =
          "Invalid password value. Whitespace passwords not allowed."
        formErrors.confirmNewPassword =
          "Invalid password value. Whitespace passwords not allowed."
        break
      case customer.newPassword.trim() !== customer.confirmNewPassword.trim():
        formErrors.newPassword = "Passwords do not match."
        formErrors.confirmNewPassword = "Passwords do not match."
        break
      default:
        break
    }

    setErrors(formErrors)
    return Object.keys(formErrors).length === 0
  }

  const ErrorMessage = ({ error, description = "" }) =>
    error ? (
      <p
        aria-describedby={description}
        className="text-xs text-idc-orange mt-1"
      >
        {error}
      </p>
    ) : null

  if (isLoggedIn()) {
    navigate(`/account/`)
  }

  return (
    <FormContainer>
      <form
        method="post"
        onSubmit={(event) => {
          handleSubmit(event)
        }}
      >
        <div className="mx-auto">
          <div className="bg-white py-6 space-y-6 text-idc-body">
            <div>
              <h2 className="text-lg leading-6 font-medium text-idc-title">
                Update your account password.
              </h2>
              <p className="mt-1 text-sm text-idc-subtle">
                Enter a new password for you account.
              </p>
            </div>

            <div className="grid gap-6">
              <div className="">
                <label htmlFor="email" className="block text-sm font-medium">
                  New Password
                </label>
                <input
                  value={customer.newPassword}
                  id="newPassword"
                  type="password"
                  name="newPassword"
                  autoComplete="password"
                  className={`${
                    errors?.newPassword ? "border-idc-orange" : ""
                  } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue`}
                  onChange={handleInputChange}
                  aria-required
                />
                <ErrorMessage
                  error={errors?.newPassword}
                  description="newPassword"
                />
              </div>

              <div className="">
                <label htmlFor="email" className="block text-sm font-medium">
                  Confirm New Password
                </label>
                <input
                  value={customer.confirmNewPassword}
                  id="confirmNewPassword"
                  type="password"
                  name="confirmNewPassword"
                  autoComplete="password"
                  className={`${
                    errors?.confirmNewPassword ? "border-idc-orange" : ""
                  } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue`}
                  onChange={handleInputChange}
                  aria-required
                />
                <ErrorMessage
                  error={errors?.confirmNewPassword}
                  description="firstName"
                />
              </div>

              <button
                type="submit"
                aria-label="update password"
                className="mt-2 w-full inline-flex justify-center rounded-full px-4 py-3 text-base font-bold text-idc-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-idc-blue sm:text-sm bg-idc-light-blue bg-opacity-50 hover:bg-opacity-75 active:bg-opacity-100 transition duration-300 ease-in-out"
              >
                Update Password
              </button>
            </div>
          </div>
        </div>
      </form>
      <p className="text-center">
        <Link
          to="/account/login"
          className="font-semibold text-idc-blue underline hover:no-underline transition duration-300 ease-in-out"
        >
          Login
        </Link>
      </p>
    </FormContainer>
  )
}

export default ResetPassword
