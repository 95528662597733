import React, { FC, Fragment, useState, useEffect } from "react"
import { Transition, Dialog } from "@headlessui/react"
import QuantitySelect from "components/elements/QuantitySelect"
import { updateBoxChoices } from "services/bold"

export interface SelectChoicesModalProps {
  isVisible: boolean
  setIsVisible: any
  targetSubscriptionDetails: any
  productDetails: any
}

const SelectChoicesModal: FC<SelectChoicesModalProps> = ({
  isVisible,
  setIsVisible,
  targetSubscriptionDetails = {},
  productDetails = {},
  handleMap = {},
}) => {
  const [choices, setChoices] = useState([])
  const [boxSize, setBoxSize] = useState(-1)
  const { orderDate, orderId } = targetSubscriptionDetails

  let selectedCoffeesCount = 0
  choices.forEach((item) => {
    selectedCoffeesCount += item.quantity
  })

  const isBoxSelectionsValid = boxSize === selectedCoffeesCount

  useEffect(() => {
    setChoices(targetSubscriptionDetails?.currentChoices)

    let choiceCount = 0
    if (targetSubscriptionDetails?.currentChoices) {
      targetSubscriptionDetails?.currentChoices.forEach(
        (choice) => (choiceCount += choice.quantity)
      )
      setBoxSize(choiceCount)
    }
  }, [targetSubscriptionDetails?.currentChoices])

  return (
    <Transition.Root show={isVisible} as={Fragment}>
      <Dialog
        as="div"
        className="overflow-y-auto fixed inset-0 z-10"
        onClose={setIsVisible}
      >
        <div className="flex justify-center items-end px-4 pt-4 pb-20 min-h-screen text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block relative align-bottom bg-white rounded-md px-6 py-8 text-left overflow-hidden shadow-xl transition-all w-full sm:my-8 sm:align-middle sm:max-w-[82rem] max-h-[calc(100vh-2rem)] sm:max-h-[calc(100vh-4rem)] overflow-y-scroll sm:p-8">
              <div className="flex justify-end">
                <Dialog.Title
                  as="h4"
                  className="text-2xl font-bold leading-6 sr-only text-idc-title"
                >
                  Choose coffees
                </Dialog.Title>
              </div>

              <div className="flex flex-col lg:flex-row xl:justify-between">
                <div className="py-12 px-6 lg:px-8 bg-idc-light lg:max-w-[23.875rem] shrink-0">
                  <div className="sticky top-8">
                    <h2 className="mb-4 font-bold text-idc-title text-[1.75rem] leading-[2.625rem]">
                      Coffee Selection
                    </h2>
                    <p className="mb-8 text-idc-body">
                      Choose your new coffee preferences by adjusting the
                      quantity of coffees
                    </p>

                    <div className="">
                      <button
                        disabled={!isBoxSelectionsValid}
                        onClick={async () => {
                          const result = await updateBoxChoices(
                            orderId,
                            orderDate,
                            choices
                          )
                          if (result.orders) {
                            setIsVisible(false)
                          }
                        }}
                        className="mb-4 rounded-full bg-idc-blue disabled:bg-[#949A9D] py-[0.8125rem] w-full text-white font-semibold disabled:bg-idc-light-blue disabled:cursor-not-allowed transition duration-300 ease-in-out hover:bg-[#F47761] active:bg-idc-orange"
                      >
                        Save Selections
                      </button>
                      <button
                        onClick={() => setIsVisible(false)}
                        className={`rounded-full border-2 border-idc-blue text-idc-blue disabled:border-[#949A9D] py-[0.8125rem] w-full font-semibold transition duration-300 ease-in-out hover:bg-idc-light-blue active:bg-idc-light-blue`}
                      >
                        Back to Subscriptions
                      </button>
                    </div>
                  </div>
                </div>

                <div className="mx-auto lg:mr-0 lg:ml-[3.5rem]">
                  <div className="flex flex-col gap-y-2 items-center mb-8 lg:flex-row">
                    <h2 className="text-2xl font-bold text-idc-title">{`Choose ${
                      boxSize !== -1 ? boxSize : ""
                    } Coffee${boxSize === 1 ? "" : "s"}`}</h2>
                    <p className="text-base font-normal text-idc-orange lg:ml-4">{`(${
                      boxSize - selectedCoffeesCount
                    }) selections remaining`}</p>
                  </div>
                  <div className="grid gap-y-8 gap-x-12 sm:grid-cols-2 xl:grid-cols-3">
                    {choices.map((choice, index) => {
                      const choiceHandle = handleMap?.[choice.product_id]
                        ? handleMap[choice.product_id]
                        : ""
                      const slotDetails = productDetails?.[choiceHandle]
                        ? productDetails[choiceHandle]
                        : null
                      let imgSrc
                      if (slotDetails && slotDetails?.images?.length > 0) {
                        imgSrc = slotDetails.images[0]
                      }
                      //make inactive if not available for sale, but let users reduce the quantity if they have already selected it
                      const controlActive: boolean =
                        Boolean(slotDetails) &&
                        Boolean(slotDetails?.availableForSale)

                      const handleQuantityChange = (value) => {
                        let currentChoiceCount = 0
                        let maxChoiceAllowed = 0

                        choices.forEach((choice) => {
                          currentChoiceCount += choice.quantity
                        })
                        if (boxSize !== -1) {
                          maxChoiceAllowed = boxSize - currentChoiceCount

                          // if it is out of stock, and has positive quantity, then set it to 0 and don't allow it to be changed
                          if (!slotDetails?.availableForSale && value > 0) {
                            value = 0
                          }
                        }

                        let change = value - choice.quantity
                        if (change > maxChoiceAllowed) change = maxChoiceAllowed
                        const updatedChoices = choices.map((item) => {
                          if (item.product_id === choice.product_id) {
                            item.quantity = change + choice.quantity
                          }
                          return item
                        })

                        setChoices(updatedChoices)
                      }

                      return (
                        <div
                          key={choice.product_id}
                          className="w-[17rem] xl:w-[15rem]"
                        >
                          <div className="relative h-[17rem] xl:h-[15rem] 2xl:h-[17rem] w-[17rem] xl:w-[15rem] 2xl:w-[17rem] aspect-square bg-gray-100 mb-6">
                            {imgSrc && (
                              <img
                                className="object-contain absolute w-full h-full"
                                src={imgSrc.src}
                                alt={imgSrc.altText}
                              />
                            )}
                          </div>

                          <p className="mb-2 font-bold text-idc-title">
                            {slotDetails
                              ? slotDetails.title
                              : `Coffee ${index + 1}`}
                          </p>
                          <p className="mb-2 text-idc-body line-clamp-3">
                            {slotDetails ? slotDetails.description : ""}
                          </p>
                          <div className="mb-4">
                            {slotDetails.availableForSale ? (
                              <QuantitySelect
                                controlActive={controlActive}
                                min={0}
                                quantity={choice.quantity || 0}
                                setQuantity={handleQuantityChange}
                              />
                            ) : !slotDetails.availableForSale &&
                              choice.quantity > 0 ? (
                              <>
                                <QuantitySelect
                                  controlActive={!controlActive}
                                  min={0}
                                  quantity={choice.quantity || 0}
                                  setQuantity={handleQuantityChange}
                                />
                                <span className="w-40 text-sm text-center text-red-700 rounded-xl border border-red-400 border-t-1 sm:block">
                                  This coffee is out of stock
                                </span>
                              </>
                            ) : (
                              <QuantitySelect
                                controlActive={controlActive}
                                min={0}
                                quantity={0}
                                setQuantity={0}
                              />
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default SelectChoicesModal
