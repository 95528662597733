import React from "react"
import { BellIcon } from "@heroicons/react/solid"
import { CheckIcon } from "@heroicons/react/solid"
import { ExclamationCircleIcon } from "@heroicons/react/solid"

const Notification = ({ notification = { type: "", messages: [] } }) => {
  const { type, messages } = notification

  let icon
  let messageStyle
  switch (type) {
    case "success":
      icon = <CheckIcon className="w-6 h-6 shrink-0" />
      messageStyle = "text-[#355944] bg-[#D7EBDF]"
      break

    case "error":
      icon = <ExclamationCircleIcon className="w-6 h-6 shrink-0" />
      messageStyle = "text-[#432B25] bg-[#FFDCD3]"
      break

    default:
      icon = <BellIcon className="w-6 h-6 shrink-0" />
      messageStyle = "text-[#49391F] bg-[#FDF0DB]"
  }

  return (
    <div className={`flex rounded-md px-4 py-[0.875rem] mb-8 ${messageStyle}`}>
      {icon}
      <div role="alert" className="ml-2">
        {messages.map((message) => (
          <p key="message" className="font-semibold text=[0.9375rem]">
            {message}
          </p>
        ))}
      </div>
    </div>
  )
}

export default Notification
