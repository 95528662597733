import React, { useEffect, useState } from "react"
import { Seo } from "components/seo"
import { Router } from "@reach/router"
import { Layout } from "components/layouts/Layout/layout"
import PrivateRoute from "components/privateRoute"
import Profile from "components/layouts/Account/Profile"
import Login from "components/layouts/Account/Login"
import Order from "components/layouts/Account/Orders"
// import Wishlist from "components/layouts/Account/Wishlist"
// import SavedForLater from "components/layouts/Account/SavedForLater"
import CreateAccount from "components/layouts/Account/CreateAccount"
import RecoverPassword from "components/layouts/Account/RecoverPassword"
import ResetPassword from "components/layouts/Account/ResetPassword"
import ManageSubscriptions from "components/layouts/Account/manage-subscriptions"
import LoyaltyRewards from "components/layouts/Account/LoyaltyRewards"
import AccountMenu from "components/layouts/Account/AccountMenu"
import Container from "components/elements/Container"
import Notification from "components/layouts/Account/Notification"
import Breadcrumbs from "components/elements/Breadcrumbs/breadcrumbs"
import { Transition } from "@headlessui/react"
import { StoreContext } from "context/store-context"
import { NotificationContext } from "context/notification-context"
import { isLoggedIn, logout } from "services/auth"
import { navigate, Link } from "gatsby"

// import { createCustomer, createCustomerFetch, getCustomerAccessTokenFetch, updateCustomerAddressFetch, getCustomerDetailsFetch } from "services/auth"

const Account = ({ location }) => {
  const [breadcrumb, setBreadcrumb] = useState([])
  const { initializeCheckout, clearCheckout } = React.useContext(StoreContext)
  const { notification, showNotification } =
    React.useContext(NotificationContext)
  const noNavigationRoutes = [
    "/account/manage-subscriptions",
    "/account/loyalty-rewards",
    "/account/login",
    "/account/register",
    "/account/recover-password",
    "/account/reset-password",
  ]
  const showNavigation = !noNavigationRoutes.includes(location?.pathname)
  if (isLoggedIn()) {
    navigate("/account/manage-subscriptions")
  }

  const handleLogout = () => {
    logout(() => {
      clearCheckout()
      initializeCheckout()
      navigate("/account/")
    })
  }

  useEffect(() => {
    if (window && window.location) {
      const slug = location.pathname.split("/")

      const home = {
        slug: "/",
        name: "Home",
      }
      const parentLink = {
        slug: `/${slug[1]}`,
        name: slug[1].replaceAll("-", " "),
      }

      let breadcrumbArray = []
      breadcrumbArray.push(home)
      breadcrumbArray.push(parentLink)

      if (slug.length >= 3) {
        const nestedLink = {
          slug: "",
          name: slug[2].replaceAll("-", " "),
        }
        breadcrumbArray.push(nestedLink)
      }

      setBreadcrumb(breadcrumbArray)
    }
  }, [location])

  return (
    <>
      <Seo title={`Account`} />
      <Container>
        <div className="flex flex-row items-center my-4">
          <Breadcrumbs breadcrumbs={breadcrumb} />
        </div>
        {showNavigation && (
          <Link to="/account">
            <p className="text-[1.75rem] font-bold text-idc-title mt-[1.625rem]">{`${
              isLoggedIn() ? "Welcome Back!" : ""
            }`}</p>
          </Link>
        )}

        <div
          className={`mt-8 lg:mt-12 mb-[7.5rem] md:mb-[7.75rem] ${
            showNavigation
              ? "grid lg:grid-cols-[298px,auto] gap-x-[5.5rem]"
              : ""
          }`}
        >
          {showNavigation ? (
            <div className="mb-8 lg:mb-0">
              {isLoggedIn() && (
                <div className="hidden lg:grid text-lg text-idc-body">
                  <Link
                    activeClassName="bg-idc-light-blue text-idc-blue font-bold"
                    partiallyActive={true}
                    to="/account/orders"
                    className="px-4 py-3 rounded-md hover:bg-idc-light hover:text-idc-blue transition duration-300 ease-in-out"
                  >
                    Order History
                  </Link>
                  <Link
                    activeClassName="bg-idc-light-blue text-idc-blue font-bold"
                    to="/account/manage-subscriptions"
                    className="px-4 py-3 rounded-md hover:bg-idc-light hover:text-idc-blue transition duration-300 ease-in-out"
                  >
                    Manage Subscriptions
                  </Link>
                  <Link
                    activeClassName="bg-idc-light-blue text-idc-blue font-bold"
                    to="/account/loyalty-rewards"
                    className="px-4 py-3 rounded-md hover:bg-idc-light hover:text-idc-blue transition duration-300 ease-in-out"
                  >
                    Rewards
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="text-left px-4 py-3 rounded-md hover:bg-idc-light hover:text-idc-blue transition duration-300 ease-in-out"
                  >
                    Logout
                  </button>
                </div>
              )}

              {isLoggedIn() && (
                <AccountMenu
                  locationProp={location}
                  handleLogout={handleLogout}
                />
              )}
            </div>
          ) : null}
          <div>
            <Transition
              show={showNotification}
              enter="transform transition duration-[400ms]"
              enterFrom="opacity-0 scale-95 "
              enterTo="opacity-100 scale-100 "
              leave="transform duration-200 transition ease-in-out"
              leaveFrom="opacity-100 scale-100 "
              leaveTo="opacity-0 scale-95 "
            >
              <Notification notification={notification} />
            </Transition>
            <Router>
              <Login path="account/login" locationProp={location} />
              <CreateAccount path="account/register" />
              <PrivateRoute path="account" component={Profile} />
              <PrivateRoute
                path="account/orders"
                component={Order}
                locationProp={location}
              />
              <PrivateRoute
                path="account/manage-subscriptions"
                component={ManageSubscriptions}
              />
              <PrivateRoute
                path="account/loyalty-rewards"
                component={LoyaltyRewards}
              />
              <RecoverPassword path="account/recover-password" />
              <ResetPassword path="account/reset-password" />
            </Router>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Account
