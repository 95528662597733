import React, { useState } from "react"
import { useQuery } from "urql"
import qs from "qs"
import { Link } from "gatsby"
import { getUser } from "services/auth"
import { formatPrice } from "utils/format-price"
import OrderDetail from "components/layouts/Account/OrderDetails"
import { OrderHistoryPlaceholder } from "components/elements/Placeholders"
import "./Orders.css"

interface QueryVariables {
  first: number | null
  last: number | null
  before: string | null
  after: string | null
}

// https://shopify.dev/api/storefront/2022-01/objects/Order#fields
const OrderQuery = `
    query customer ($customerAccessToken: String!, $first: Int, $last: Int, $before: String, $after: String) {
      customer (customerAccessToken: $customerAccessToken) {
        id
        orders (first: $first, after: $after, last: $last, before:$before, reverse: true) {
          edges {
            cursor
            node {
              id
              name
              orderNumber
              fulfillmentStatus
              financialStatus
              customerLocale
              processedAt
              currentTotalPrice {
                amount
                currencyCode
              }
              currentSubtotalPrice {
                amount
                currencyCode
              }
              currentTotalPrice {
                amount
                currencyCode
              }
              totalShippingPriceV2 {
                amount
                currencyCode
              }
              totalRefundedV2 {
                amount
                currencyCode
              }
              currentTotalTax {
                amount
                currencyCode
              }
              lineItems (first:25) {
                edges {
                  cursor
                  node {
                    title
                    currentQuantity
                    discountedTotalPrice {
                      amount
                      currencyCode
                    }
                    originalTotalPrice {
                      amount
                      currencyCode
                    }
                    variant {
                      title
                      sku
                      image {
                        altText
                        src
                      }
                      product {
                        handle
                        productType
                      }
                    }
                  }
                }
              }
              discountApplications (first:5) {
                edges {
                  node {
                    value {
                      __typename
                      ... on MoneyV2 {
                        amount
                        currencyCode
                      }
                    }
                    allocationMethod
                    targetSelection
                    targetType
                  }
                }
              }
              shippingAddress {
                name
                address1
                city
                provinceCode
                zip
                country
              }
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  `

const Orders = ({ locationProp }) => {
  const searchString = locationProp?.search ?? ""
  const queries = qs.parse(searchString.replace("?", ""))

  const user = getUser()
  const errors = []
  let orders = []
  const [queryVariables, setQueryVariables]: [QueryVariables, any] = useState({
    first: 10,
    last: null,
    before: null,
    after: null,
  })
  const [orderQueryResult] = useQuery({
    query: OrderQuery,
    variables: {
      ...queryVariables,
      customerAccessToken: user.accessToken,
    },
  })
  const { data, fetching, error } = orderQueryResult

  const handlePrevious = (orders: any) => {
    const cursor = orders.edges[0].cursor
    setQueryVariables({
      before: cursor,
      last: 10,
      after: null,
      first: null,
    })
  }

  const handleNext = (orders: any) => {
    const cursor = orders.edges[orders.edges.length - 1].cursor
    setQueryVariables({
      after: cursor,
      first: 10,
      before: null,
      last: null,
    })
  }

  if (data) {
    // console.log({data})
    orders = data?.customer?.orders?.edges
    if (queries?.id) {
      const order = orders.find((order) => order.node.orderNumber == queries.id)
      if (order) return <OrderDetail order={order.node} />
    }
  }

  if (error) {
    console.warn(error)
    errors.push(...error.graphQLErrors)
  }

  return (
    <>
      <h1 className="font-bold text-[1.625rem] leading-8 text-idc-title mb-4">
        Order History
      </h1>
      <p className="max-w-[40rem] text-idc-body mb-8">
        Click on an order to review it. Once submitted, orders can not be edited
        or changed. To edit upcoming subscription orders, go to{" "}
        <Link
          to="/account/manage-subscriptions"
          className="font-semibold text-idc-blue underline hover:text-[#F47761] active:text-idc-orange transition duration-300 ease-in-out"
        >
          Manage Subscriptions
        </Link>
        .
      </p>

      {orders.length > 0 ? (
        <table className="table-fixed text-left border-collapse w-full">
          <thead className="sr-only lg:not-sr-only">
            <tr className="rounded-lg bg-idc-light">
              <th className="rounded-l-lg table-header">Order Number</th>
              <th className="hidden lg:block table-header">Date</th>
              <th className="table-header">Payment Status</th>
              <th className="hidden lg:block table-header">Order Status</th>
              <th className="text-right rounded-r-lg table-header">Total</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => {
              const { node } = order
              const date = new Date(node.processedAt)
              const formattedDate = date.toLocaleDateString("en-CA", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
              const price = formatPrice(
                node.currentTotalPrice.currencyCode,
                node.currentTotalPrice.amount
              )

              return (
                <tr
                  key={node.id}
                  className="even:border-x-2 even:border-white even:bg-idc-light"
                >
                  <td className="table-data">
                    <Link
                      to={`?id=${node.orderNumber}`}
                      className="text-idc-blue font-bold underline hover:no-underline"
                    >{`#${node.orderNumber}`}</Link>
                    <p aria-hidden className="lg:hidden">
                      {formattedDate}
                    </p>
                  </td>
                  <td className="hidden lg:block table-data">
                    <p>{formattedDate}</p>
                  </td>
                  <td className="table-data">
                    <p>{node.financialStatus}</p>
                    <p aria-hidden className="lg:hidden">
                      {node.fulfillmentStatus}
                    </p>
                  </td>
                  <td className="hidden lg:block table-data">
                    <p>{node.fulfillmentStatus}</p>
                  </td>
                  <td className="text-right table-data">
                    <p aria-hidden className="lg:hidden font-semibold">
                      Total
                    </p>
                    <p>{price}</p>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      ) : (
        !fetching && (
          <>
            <p className="text-idc-body text-[15px] lg:text-[17px] mb-6 max-w-[40rem]">
              You currently have no existing orders. To start seeing items in
              your order history, check out our products!
            </p>
            <Link
              to="/"
              className="inline-block py-3 px-6 w-full lg:w-[19.5rem] text-center font-bold text-large rounded-full transition duration-300 ease-in-out bg-idc-orange text-white hover:bg-idc-blue hover:bg-opacity-75 active:bg-idc-blue"
            >
              Continue Shopping
            </Link>
          </>
        )
      )}
      <div className="flex w-full mt-4">
        {data?.customer?.orders?.pageInfo.hasPreviousPage && (
          <button
            className="justify-self-start flex justify-between items-center w-full pl-6 pr-5 py-[0.375rem] text-[0.9375rem] leading-[2.25rem] font-medium text-center text-idc-body-title rounded focus:outline-none group"
            onClick={() => handlePrevious(data.customer.orders)}
          >
            Prev
          </button>
        )}
        {data?.customer?.orders?.pageInfo.hasNextPage && (
          <button
            className="justify-self-end flex justify-between items-center w-full pl-6 pr-5 py-[0.375rem] text-[0.9375rem] leading-[2.25rem] font-medium text-center text-idc-body-title rounded focus:outline-none group"
            onClick={() => handleNext(data.customer.orders)}
          >
            Next
          </button>
        )}
      </div>
      <ul>
        {errors.map((error) => (
          <li key={error.message}>{error.message}</li>
        ))}
      </ul>
      {fetching && <OrderHistoryPlaceholder loading={fetching} />}
    </>
  )
}

export default Orders
