import React, { useState, useEffect } from "react"
import { updateProfile } from "services/auth"
import { NotificationContext } from "context/notification-context"

interface CustomerProfile {
  lastName: string
  firstName: string
  email: string
  phone: string
  acceptsMarketing: boolean
}

const UpdateProfile = ({
  profileToUpdate = {},
  setShowProfileForm,
  reexecuteProfileQuery,
}) => {
  const { sendNotification } = React.useContext(NotificationContext)
  const [errors, setErrors]: [object[], any] = useState({})
  const [isLoading, setIsLoading]: [boolean, any] = useState(false)
  const { lastName, firstName, email } = profileToUpdate
  const [profile, setProfile]: [CustomerProfile, any] = useState({
    lastName: "",
    firstName: "",
    email: "",
    phone: "",
    acceptsMarketing: false,
  })

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      setProfile({
        lastName: profileToUpdate?.lastName ?? "",
        firstName: profileToUpdate?.firstName ?? "",
        email: profileToUpdate?.email ?? "",
        phone: profileToUpdate?.phone ?? "",
        acceptsMarketing: profileToUpdate?.acceptsMarketing ?? false,
      })
    }
    return () => {
      isSubscribed = false
    }
  }, [profileToUpdate])

  const handleInputChange = (e) => {
    setErrors({})
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    })
  }

  const handleCheckboxChange = () => {
    setProfile({
      ...profile,
      acceptsMarketing: !profile.acceptsMarketing,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const formValid = validateForm()
    setIsLoading(true)

    if (formValid) {
      // update an existing profile
      updateProfile(email, firstName, lastName, profile).then((result) => {
        if (result?.customerUpdate?.customer) {
          reexecuteProfileQuery({ requestPolicy: "network-only" })
          sendNotification({
            type: "success",
            messages: ["Your profile was updated successfully"],
          })
        }

        if (result?.customerUpdate?.userErrors.length > 0) {
          sendNotification({
            type: "error",
            messages: result?.customerUpdate?.userErrors.map((e) => e.message),
          })
        }

        if (result?.error) {
          sendNotification({
            type: "error",
            messages: [result.error.message],
          })
        }

        setIsLoading(false)
      })
    } else {
      setIsLoading(false)
    }
  }

  const validateForm = () => {
    const formErrors = {}
    // email validation
    switch (true) {
      case profile.email.trim() === "":
        formErrors.email = "Email is required."
        break
      case !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(profile.email):
        formErrors.email = "Please enter valid email address."
        break
      default:
        break
    }

    // firstname validation
    switch (true) {
      case profile.firstName.trim() === "":
        formErrors.firstName =
          "Invalid first name entered. First name must not be whitespace"
        break
      default:
        break
    }

    setErrors(formErrors)
    return Object.keys(formErrors).length === 0
  }

  const ErrorMessage = ({ error, description = "" }) =>
    error ? (
      <p
        aria-describedby={description}
        className="text-xs text-idc-orange mt-1"
      >
        {error}
      </p>
    ) : null

  return (
    <>
      <form
        className="mt-12"
        method="post"
        onSubmit={(event) => {
          handleSubmit(event)
        }}
      >
        <div className="bg-white pb-6 space-y-6 text-idc-body">
          <div className="grid grid-cols-6 gap-6 lg:max-w-[34.75rem]">
            <div className="col-span-6 sm:col-span-3">
              <label htmlFor="firstName" className="block text-sm font-medium">
                First Name
              </label>
              <input
                value={profile.firstName}
                id="firstName"
                type="text"
                name="firstName"
                autoComplete="given-name"
                className={`${
                  errors?.firstName ? "border-idc-orange" : ""
                } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue`}
                onChange={handleInputChange}
              />
              <ErrorMessage error={errors?.firstName} description="firstName" />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label htmlFor="lastName" className="block text-sm font-medium">
                Last Name
              </label>
              <input
                value={profile.lastName}
                id="lastName"
                type="text"
                name="lastName"
                autoComplete="family-name"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue"
                onChange={handleInputChange}
              />
            </div>

            <div className="col-span-6">
              <label htmlFor="email" className="block text-sm font-medium">
                Email
              </label>
              <input
                value={profile.email}
                id="email"
                type="text"
                name="email"
                autoComplete="street-profile"
                className={`${
                  errors?.email ? "border-idc-orange" : ""
                } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue`}
                onChange={handleInputChange}
                aria-required
              />
              <ErrorMessage error={errors?.email} description="email" />
            </div>

            <div className="col-span-6">
              <label htmlFor="phone" className="block text-sm font-medium">
                Phone Number
              </label>
              <input
                value={profile.phone}
                id="phone"
                type="text"
                name="phone"
                autoComplete="tel"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue"
                onChange={handleInputChange}
              />
            </div>
          </div>

          <label
            htmlFor="acceptsMarketing"
            className="font-medium text-idc-body text-sm flex items-center cursor-pointer"
          >
            <div className="relative mr-2 p-[0.125rem] h-[1.25rem] w-[1.25rem] border border-gray-300 rounded-sm">
              <input
                checked={profile.acceptsMarketing}
                id="acceptsMarketing"
                type="checkbox"
                name="acceptsMarketing"
                className="peer sr-only cursor-pointer"
                onChange={handleCheckboxChange}
              />
              <div
                className={`peer-focus-visible:ring-idc-blue peer-focus-visible:ring-offset-2 peer-focus-visible:ring-2 cursor-pointer w-full h-full rounded-sm ${
                  profile.acceptsMarketing ? "bg-idc-orange" : ""
                }`}
              ></div>
            </div>
            Receive discounts and offers
          </label>
        </div>
        <div className="flex items-center pt-3">
          <button
            className="inline-block mr-8 py-3 px-6 w-[10.5rem] text-center font-bold text-large rounded-full transition duration-300 ease-in-out bg-idc-orange text-white hover:bg-idc-blue hover:bg-opacity-75 active:bg-idc-blue"
            type="submit"
            disabled={isLoading}
            aria-label="update default profile"
          >
            Save Changes
          </button>

          <button
            type="button"
            onClick={() => setShowProfileForm(false)}
            className="font-semibold text-idc-blue underline hover:text-[#F47761] active:text-idc-orange transition duration-300 ease-in-out"
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  )
}

export default UpdateProfile
