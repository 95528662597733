import React, { useState, useEffect } from "react"
import { Tab } from "@headlessui/react"
import { Link } from "gatsby"
import {
  updateFrequency,
  toggleOrderStatus,
  toggleSubscriptionStatus,
  updateOrderDate,
  getShippingRates,
  getCardDetails,
  updateShippingAddress,
  updateBillingAddress,
  cancelSubscription,
} from "services/bold"
import { CountryDropdown, RegionDropdown } from "react-country-region-selector"
import { PaymentDetailsPlaceholder } from "components/elements/Placeholders"
import "./index.css"

const SubscriptionDetail = ({
  orderObject,
  products,
  handleMap,
  groupMap,
  startChoiceSelection,
}) => {
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [order, setOrder] = useState(orderObject)
  const [showDetails, setShowDetails] = useState(false)
  const [showEditDate, setShowEditDate] = useState(false)
  const [showEditFrequency, setShowEditFrequency] = useState(false)
  const [showEditShipping, setShowEditShipping] = useState(false)
  const [showEditPayment, setShowEditPayment] = useState(false)
  const [nextOrderDate, setNextOrderDate] = useState(
    order.next_active_ship_date
  )
  const [shippingRate, setShippingRate] = useState(order.order_shipping_rate)
  const [billingMatchesShipping, setBillingMatchesShipping] = useState(false)
  const [orderProduct] = order.order_products
  const orderProductHandle = orderProduct
    ? handleMap?.[orderProduct.product_id]
    : ""
  const orderProductDetails = orderProductHandle
    ? products?.[orderProductHandle]
    : {}
  const [orderProductImage] = orderProductDetails?.images
    ? orderProductDetails.images
    : []

  const frequencyTypes = groupMap?.[order.group_id]

  const [upcomingOrdersExpanded, setUpcomingOrdersExpanded] = useState(false)
  const [historyExpanded, setHistoryExpanded] = useState(false)

  const [frequencyNum, setFrequencyNum] = useState(order.interval_number)
  const [frequencyType, setFrequencyType] = useState(order.interval_type_id)

  const [, setErrors] = useState([])
  const [address, setAddress] = useState({
    last_name: order.last_name ?? "",
    first_name: order.first_name ?? "",
    address1: order.address1 ?? "",
    address2: order.address2 ?? "",
    company: order.company ?? "",
    province: order.province ?? "",
    country: order.country ?? "Canada",
    zip: order.zip ?? "",
    city: order.city ?? "",
    phone: order.phone ?? "",
  })
  const [billingAddress, setBillingAddress] = useState({
    billing_last_name: order.billing_last_name ?? "",
    billing_first_name: order.billing_first_name ?? "",
    billing_address1: order.billing_address1 ?? "",
    billing_address2: order.billing_address2 ?? "",
    billing_company: order.billing_company ?? "",
    billing_province: order.billing_province ?? "",
    billing_country: order.billing_country ?? "Canada",
    billing_zip: order.billing_zip ?? "",
    billing_city: order.billing_city ?? "",
    billing_phone: order.billing_phone ?? "",
  })

  const parseDate = (dateString: String, type = "long") => {
    if (dateString) {
      const date = new Date(dateString)
      const formattedDate =
        type === "long"
          ? date.toLocaleDateString("en-CA", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          : date.toLocaleDateString("en-CA", {
              year: "numeric",
              month: "numeric",
            })
      return formattedDate
    }

    return ""
  }

  const getNextOrderDate = () => {
    let nextOrderDate = ""
    if (order?.next_orders) {
      const orderExceptions = order?.order_exceptions ?? []
      const nextValidDate = order.next_orders.find(
        (order) => !orderExceptions.includes(order)
      )
      nextOrderDate = nextValidDate ?? ""
    }

    return nextOrderDate
  }

  const handleInputChange = (e, billing = false) => {
    setErrors([])

    if (billing) {
      setBillingAddress({
        ...billingAddress,
        [e.target.name]: e.target.value,
      })
    } else {
      setAddress({
        ...address,
        [e.target.name]: e.target.value,
      })
    }
  }

  const handleSelectChange = (
    value: string,
    name: string,
    billing: boolean = false
  ) => {
    setErrors([])
    if (billing) {
      setBillingAddress({
        ...billingAddress,
        [name]: value,
      })
    } else {
      setAddress({
        ...address,
        [name]: value,
      })
    }
  }

  const handleBillingMatchesShippingChange = () => {
    setBillingMatchesShipping(!billingMatchesShipping)
  }

  const handleBillingAddressUpdate = async () => {
    let newAddress = {}
    if (billingMatchesShipping) {
      for (const [key, value] of Object.entries(address)) {
        newAddress[`billing_${key}`] = value
      }
    } else {
      newAddress = { ...billingAddress }
    }

    const result = await updateBillingAddress(order.id, newAddress)
    if (result.id) {
      setOrder({
        ...order,
        ...result,
      })
      setShowEditPayment(!showEditPayment)
    }
  }

  useEffect(() => {
    let isSubscribed = true
    setPaymentLoading(true)
    const fetchRates = async () => {
      const shippingResult = await getShippingRates(order.id)
      const cardResult = await getCardDetails(order.id)
      if (isSubscribed) {
        setOrder({
          ...order,
          available_shipping_rates: shippingResult?.shipping_rates ?? [],
          credit_card: cardResult?.credit_card ?? {},
        })
        setPaymentLoading(false)
      }
    }

    fetchRates()
    return () => {
      isSubscribed = false
    }
  }, [])

  return (
    <div className="border rounded-md px-4 py-6 lg:p-8" key={order.id}>
      <div className="flex flex-col lg:flex-row gap-6 lg:gap-10">
        <div className="relative aspect-[456/480] h-[21rem] lg:h-[30rem] shrink-0">
          {orderProductImage ? (
            <img
              src={orderProductImage.src}
              alt={orderProductImage.altText}
              className="absolute w-full h-full object-cover object-center rounded-md"
              loading="lazy"
            />
          ) : null}
        </div>
        <div className="grow">
          <p className="text-idc-body text-[1.0625rem] mb-6">
            Subscription number:{" "}
            <span className="font-semibold">#{order.id}</span>
          </p>

          {order.order_products.length > 1 ? (
            <div>
              <p className="font-semibold text-base mb-2 text-idc-title">
                Products
              </p>
              {order.order_products.map((product) => (
                <div className="flex justify-between mb-2">
                  <p className="text-xl font-bold text-idc-title mb-2">
                    {product.product_title} - {product.quantity}
                  </p>
                  <p className="text-xl font-medium">${product.price}</p>
                </div>
              ))}
            </div>
          ) : (
            <>
              <p className="text-2xl font-bold text-idc-title mb-2">
                {orderProduct?.product_title} - {orderProduct?.quantity}
              </p>
              <p className="text-2xl font-medium">${orderProduct?.price}</p>
            </>
          )}

          <div className="grid lg:grid-cols-2 gap-6 text-idc-body text-[1.0625rem] mt-8">
            <div>
              <div className="mb-6">
                <p className="font-semibold text-base mb-2 text-idc-title">
                  Shipping information
                </p>
                <p>
                  {order.first_name} {order.last_name}
                </p>
                <p>{order.address1}</p>
                <p>{order.address2}</p>
                <p>
                  {order.city}, {order.zip}
                </p>
                <p>{order.country}</p>
              </div>

              <div className="mb-6">
                <p className="font-semibold text-base mb-2 text-idc-title">
                  Payment Information
                </p>
                <PaymentDetailsPlaceholder loading={paymentLoading}>
                  <p>XXXX XXXX XXXX {order?.credit_card?.last_four}</p>
                  <p>{parseDate(order?.credit_card?.expiry_date, "short")}</p>
                </PaymentDetailsPlaceholder>
              </div>
            </div>

            <div>
              <div className="mb-6">
                <div className="flex justify-between items-center">
                  <p className="font-semibold text-base mb-2 text-idc-title">
                    Next Order Date
                  </p>
                  <button
                    aria-label={`${
                      showEditDate ? "Cancel" : "Change next order date"
                    }`}
                    className="selection-link"
                    onClick={() => setShowEditDate(!showEditDate)}
                  >{`${showEditDate ? "Cancel" : "Change"}`}</button>
                </div>
                <Tab.Group
                  key={`nex-order-date-${showEditDate}-${order.id}`}
                  defaultIndex={showEditDate ? 1 : 0}
                  onChange={(index) => setShowEditDate(index === 1)}
                >
                  <Tab.List className="hidden">
                    <Tab>Display Next Order Date</Tab>
                    <Tab>Change Next Order Date</Tab>
                  </Tab.List>
                  <Tab.Panels>
                    <Tab.Panel>
                      <p>{parseDate(getNextOrderDate())}</p>
                    </Tab.Panel>
                    <Tab.Panel className="flex flex-col items-end">
                      <div className="w-full">
                        <input
                          type="date"
                          className="text-idc-blue text-base font-medium border rounded w-full px-4 py-3"
                          value={nextOrderDate}
                          onChange={(e) => setNextOrderDate(e.target.value)}
                        />
                      </div>
                      <button
                        className="detail-save-button"
                        onClick={async () => {
                          const result = await updateOrderDate(
                            order.id,
                            nextOrderDate
                          )
                          if (result.id) {
                            setOrder({
                              ...order,
                              ...result,
                            })
                            setShowEditDate(!showEditDate)
                          }
                        }}
                      >
                        Save Changes
                      </button>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>

              <div className="mb-6">
                <div className="flex justify-between items-center">
                  <p className="font-semibold text-base mb-2 text-idc-title">
                    Order Frequency
                  </p>

                  <button
                    aria-label={`${
                      showEditFrequency ? "Cancel" : "Change order frequency"
                    }`}
                    className="selection-link"
                    onClick={() => setShowEditFrequency(!showEditFrequency)}
                  >{`${showEditFrequency ? "Cancel" : "Change"}`}</button>
                </div>
                <Tab.Group
                  key={`delivery-frequency-${showEditFrequency}-${order.id}`}
                  defaultIndex={showEditFrequency ? 1 : 0}
                  onChange={(index) => setShowEditFrequency(index === 1)}
                >
                  <Tab.List className="hidden">
                    <Tab>Display Order Frequency</Tab>
                    <Tab>Change Order Frequency</Tab>
                  </Tab.List>
                  <Tab.Panels>
                    <Tab.Panel>
                      <p>
                        Every {order.interval_number}{" "}
                        {order.order_interval_type.interval_text}(s)
                      </p>
                    </Tab.Panel>
                    <Tab.Panel className="flex flex-col items-end">
                      <div className="flex gap-x-2 w-full">
                        <label className="sr-only" htmlFor="frequency_num">
                          Choose Delivery Frequency
                        </label>
                        <select
                          className="grow text-idc-blue font-medium mt-1 block pl-4 pr-10 py-3 text-base border border-gray-300 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm rounded-md"
                          name="frequency_num"
                          id="frequency_num"
                          value={frequencyNum}
                          onChange={(e) => setFrequencyNum(e.target.value)}
                        >
                          {Array(frequencyTypes?.frequency_info.frequency_max)
                            .fill("")
                            .map((item, index) => (
                              <option
                                key={`frequency_num-${index}`}
                                value={index + 1}
                              >
                                {index + 1}
                              </option>
                            ))}
                        </select>

                        <label
                          className="sr-only"
                          htmlFor="frequency_type_text"
                        >
                          Choose Delivery Interval
                        </label>
                        <select
                          className="text-idc-blue grow font-medium mt-1 block pl-4 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm rounded-md"
                          name="frequency_type_text"
                          id="frequency_type_text"
                          value={frequencyType}
                          onChange={(e) => setFrequencyType(e.target.value)}
                        >
                          {frequencyTypes.frequency_info.frequency_types.map(
                            (type) => (
                              <option
                                key={type.interval_id}
                                value={type.interval_id}
                              >
                                {type.interval_text}(s)
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <button
                        className="detail-save-button"
                        onClick={async () => {
                          const newFrequency = await updateFrequency(
                            order.id,
                            frequencyNum,
                            frequencyType
                          )
                          if (newFrequency?.next_ship_date) {
                            setOrder({
                              ...order,
                              ...newFrequency,
                            })
                            setShowEditFrequency(!showEditFrequency)
                          }
                        }}
                      >
                        Save Changes
                      </button>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>

              <div className="mb-6">
                <div className="flex justify-between">
                  <p className="font-semibold text-base mb-2 text-idc-title">
                    Subscription Status
                  </p>
                  <button
                    aria-label={
                      order.is_paused
                        ? "Resume subscription"
                        : "Pause subscription"
                    }
                    className="selection-link"
                    onClick={async () => {
                      const result = await toggleSubscriptionStatus(
                        order.id,
                        order.is_paused
                      )
                      if (result.id) {
                        setOrder({
                          ...order,
                          ...result,
                        })
                      }
                    }}
                  >
                    {order.is_paused ? "Resume" : "Pause"}
                  </button>
                </div>
                <p className={`${order.is_paused ? "text-idc-orange" : ""}`}>
                  {order.is_paused ? "Paused" : "Active"}
                </p>
              </div>
            </div>
            <button
              onClick={() => setShowDetails(!showDetails)}
              aria-expanded={showDetails}
              aria-controls={`${order.id}-details`}
              className="lg:col-span-2 rounded-full text-sm leading-[2.375rem] py-[5px] bg-idc-light-blue bg-opacity-50 font-bold text-idc-blue hover:bg-opacity-75 active:bg-opacity-100 transition duration-300 ease-in-out"
            >
              View Details
            </button>
          </div>
        </div>
      </div>

      {showDetails && (
        <div
          id={`${order.id}-details`}
          className="border-t pt-6 lg:pt-8 mt-6 lg:mt-8 grid lg:grid-cols-2 gap-x-10 gap-y-12"
        >
          <div>
            <p className="text-idc-title font-semibold text-2xl mb-2 lg:mb-6">
              Upcoming Orders
            </p>
            <ul
              className={`text-idc-body relative ${
                !upcomingOrdersExpanded && order.next_orders.length > 4
                  ? "collapsed-detail overflow-hidden"
                  : ""
              }`}
              id="upcoming-orders"
            >
              <li className="flex lg:hidden pb-2 border-b">
                <p className="subscription-detail-subtitle w-[7.5rem] lg:w-[9.75rem] mr-4 lg:mr-6">
                  Order Date
                </p>
                <p className="subscription-detail-subtitle flex-grow">
                  Selections
                </p>
              </li>
              {order.build_a_box_next_orders.length ? (
                <>
                  {order.build_a_box_next_orders.map((upcomingOrder) => {
                    const orderStatus: boolean =
                      !order.order_exceptions.includes(upcomingOrder.date)
                    const orderChoices: any[] = upcomingOrder.choices.filter(
                      (choice) => choice.quantity > 0
                    )

                    return (
                      <li
                        className="flex flex-row first:pt-2 py-4 lg:py-5 border-b last:border-b-0 lg:border-0"
                        key={upcomingOrder.date}
                      >
                        <div className="w-[7.5rem] lg:w-[9.75rem] mr-4 lg:mr-6 shrink-0">
                          <p className="subscription-detail-subtitle hidden lg:block">
                            Order Date
                          </p>
                          <p>{parseDate(upcomingOrder.date)}</p>
                        </div>
                        <div className="flex-grow">
                          <p className="subscription-detail-subtitle hidden lg:block">
                            Selections
                          </p>
                          <div className="flex flex-col lg:flex-row gap-x-6 gap-y-2">
                            <div className="flex-grow mb-4 lg:mb-0">
                              <ul>
                                {orderChoices.map((upcomingOrderChoice) => {
                                  const choiceHandle =
                                    handleMap[upcomingOrderChoice.product_id]
                                  const productTitle = products[choiceHandle]
                                    ? products[choiceHandle].title
                                    : upcomingOrderChoice.product_id
                                  return (
                                    <p
                                      key={productTitle}
                                    >{`${productTitle}  x${upcomingOrderChoice.quantity}`}</p>
                                  )
                                })}
                              </ul>
                            </div>
                            <div className="flex justify-between lg:justify-start lg:flex-col-reverse lg:items-start shrink-0">
                              <button
                                className="selection-link"
                                onClick={() => {
                                  startChoiceSelection(
                                    order.id,
                                    upcomingOrder.date,
                                    upcomingOrder.choices
                                  )
                                }}
                              >
                                Make Selections
                              </button>
                              <button
                                className="selection-link"
                                onClick={async () => {
                                  const result = await toggleOrderStatus(
                                    order.id,
                                    orderStatus,
                                    upcomingOrder.date
                                  )
                                  if (result?.next_active_ship_date) {
                                    setOrder({
                                      ...order,
                                      ...result,
                                    })
                                  }
                                }}
                              >{`${
                                orderStatus ? "Skip" : "Resume"
                              } Order`}</button>
                            </div>
                          </div>
                        </div>
                      </li>
                    )
                  })}
                </>
              ) : (
                <>
                  {order.next_orders.map((upcomingOrder) => {
                    return (
                      <li
                        className="flex flex-row first:pt-2 py-4 lg:py-5 border-b last:border-b-0 lg:border-0"
                        key={upcomingOrder}
                      >
                        <div className="w-[7.5rem] lg:w-[9.75rem] mr-4 lg:mr-6 shrink-0">
                          <p className="subscription-detail-subtitle hidden lg:block">
                            Order Date
                          </p>
                          <p>{parseDate(upcomingOrder)}</p>
                        </div>
                        <div className="flex-grow"></div>
                      </li>
                    )
                  })}
                </>
              )}
            </ul>
            {order.build_a_box_next_orders.length > 4 && (
              <button
                aria-expanded={upcomingOrdersExpanded}
                aria-controls="upcoming-orders"
                onClick={() => {
                  setUpcomingOrdersExpanded(!upcomingOrdersExpanded)
                }}
                className="font-bold text-sm text-idc-blue h-12 w-full rounded-full bg-idc-light-blue border mt-9 bg-opacity-50 hover:bg-opacity-75 active:bg-opacity-100 transition duration-300 ease-in-out"
              >{`${
                upcomingOrdersExpanded ? "View Fewer" : "View More"
              } Upcoming Orders`}</button>
            )}

            <p className="text-idc-title font-semibold text-2xl mt-12 mb-2 lg:mb-6">
              Transaction History
            </p>
            <ul
              className={`text-idc-body relative ${
                !historyExpanded && order.order_logs.length > 4
                  ? "collapsed-detail overflow-hidden"
                  : ""
              }`}
              id="upcoming-orders"
            >
              {order.order_logs.map((historyItem) => {
                return (
                  <li
                    className="flex flex-row justify-between gap-x-6"
                    key={historyItem.id}
                  >
                    <div className="flex flex-col lg:flex-row lg:justify-between lg:grow-[2]">
                      <div>
                        <p className="subscription-detail-subtitle hidden lg:block">
                          Order Number
                        </p>
                        <Link
                          to={`/account/orders/?id=${historyItem.shopify_order_token}`}
                          className="text-idc-blue font-semibold"
                        >
                          {historyItem.shopify_order_num}
                        </Link>
                      </div>
                      <div className="mt-1 lg:mt-0">
                        <p className="subscription-detail-subtitle hidden lg:block">
                          Date
                        </p>
                        <p>{parseDate(historyItem.purchase_date)}</p>
                      </div>
                    </div>
                    <div className="text-right lg:grow">
                      <p className="subscription-detail-subtitle">Totals</p>
                      <p>${historyItem.price}</p>
                      <p>Shipping: ${historyItem.shipping}</p>
                      <p>Tax: ${historyItem.tax}</p>
                      <p>Coupon: ${historyItem.discount_amount}</p>
                    </div>
                  </li>
                )
              })}
            </ul>
            {order.order_logs.length > 4 && (
              <button
                aria-expanded={historyExpanded}
                aria-controls="upcoming-orders"
                onClick={() => {
                  setHistoryExpanded(!historyExpanded)
                }}
                className="font-bold text-sm text-idc-blue h-12 w-full rounded-full bg-idc-light-blue border mt-9 bg-opacity-50 hover:bg-opacity-75 active:bg-opacity-100 transition duration-300 ease-in-out"
              >{`${
                historyExpanded ? "View Fewer" : "View All"
              } Transactions`}</button>
            )}
          </div>

          <div className="text-idc-body">
            <div className="flex justify-between items-baseline mb-4">
              <p className="font-semibold text-idc-title text-2xl w-[16.25rem] lg:w-[22.875rem]">
                Shipping & Delivery Information
              </p>
              <button
                aria-controls="shipping-and-delivery"
                onClick={() => setShowEditShipping(!showEditShipping)}
                className="selection-link ml"
              >{`${showEditShipping ? "Cancel" : "Edit"}`}</button>
            </div>
            <Tab.Group
              key={`shipping-delivery-${showEditShipping}-${order.id}`}
              defaultIndex={showEditShipping ? 1 : 0}
              onChange={(index) => setShowEditShipping(index === 1)}
            >
              <Tab.List className="hidden">
                <Tab>Display Shipping and Delivery Information</Tab>
                <Tab>Change Shipping and Delivery Information</Tab>
              </Tab.List>
              <Tab.Panels id="shipping-and-delivery">
                <Tab.Panel>
                  <p className="font-semibold text-base mb-[10px] text-idc-title">
                    Shipping Address
                  </p>
                  <p>
                    {order.first_name} {order.last_name}
                  </p>
                  <p>{order.address1}</p>
                  <p>{order.address2}</p>
                  <p>
                    {order.city}, {order.zip}
                  </p>
                  <p>{order.country}</p>

                  <p className="font-semibold text-base mb-[10px] text-idc-title mt-6">
                    Shipping Address
                  </p>
                  <p>{`${order.order_shipping_rate.name} - ${order.order_shipping_rate.price}`}</p>
                </Tab.Panel>
                <Tab.Panel>
                  <form className="bg-white pb-6 space-y-6 text-idc-blue">
                    <p className="font-semibold text-base text-idc-title mb-4">
                      Shipping Address
                    </p>

                    <div className="grid grid-cols-6 gap-6 pb-6 border-b">
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="first_name"
                          className="detail-input-label"
                        >
                          First Name
                        </label>
                        <input
                          value={address.first_name}
                          id="first_name"
                          type="text"
                          name="first_name"
                          autoComplete="given-name"
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm"
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="last_name"
                          className="detail-input-label"
                        >
                          Last Name
                        </label>
                        <input
                          value={address.last_name}
                          id="last_name"
                          type="text"
                          name="last_name"
                          autoComplete="family-name"
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm"
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="col-span-6">
                        <label
                          htmlFor="address1"
                          className="detail-input-label"
                        >
                          Address
                        </label>
                        <input
                          value={address.address1}
                          id="address1"
                          type="text"
                          name="address1"
                          autoComplete="street-address"
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm"
                          onChange={handleInputChange}
                          required
                        />
                      </div>

                      <div className="col-span-6">
                        <label
                          htmlFor="address2"
                          className="detail-input-label"
                        >
                          Appartment/Unit Number{" "}
                          <span className="text-xs">(optional)</span>
                        </label>
                        <input
                          value={address.address2}
                          id="address2"
                          type="text"
                          name="address2"
                          autoComplete="off"
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm"
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="col-span-6">
                        <label htmlFor="company" className="detail-input-label">
                          Company
                        </label>
                        <input
                          value={address.company}
                          id="company"
                          type="text"
                          name="company"
                          autoComplete="organization"
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm"
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="country" className="detail-input-label">
                          Country
                        </label>
                        <CountryDropdown
                          classes="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm"
                          id="country"
                          name="country"
                          showDefaultOption={false}
                          value={address.country}
                          onChange={(val) => handleSelectChange(val, "country")}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="province"
                          className="detail-input-label"
                        >
                          Province
                        </label>
                        <RegionDropdown
                          id="province"
                          name="province"
                          country={address.country}
                          value={address.province}
                          classes="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm"
                          onChange={(val) =>
                            handleSelectChange(val, "province")
                          }
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="city" className="detail-input-label">
                          City
                        </label>
                        <input
                          value={address.city}
                          id="city"
                          type="text"
                          name="city"
                          autoComplete="address-level2"
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm"
                          onChange={handleInputChange}
                          required
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="zip" className="detail-input-label">
                          Postal Code
                        </label>
                        <input
                          value={address.zip}
                          id="zip"
                          type="text"
                          name="zip"
                          autoComplete="postal-code"
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm"
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="col-span-6">
                        <label htmlFor="phone" className="detail-input-label">
                          Phone Number
                        </label>
                        <input
                          value={address.phone}
                          id="phone"
                          type="text"
                          name="phone"
                          autoComplete="tel"
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <p className="subscription-detail-subtitle ">
                      Shipping Method
                    </p>
                    <label className="sr-only" htmlFor="shipping_rate">
                      Choose Shipping Method
                    </label>
                    <select
                      className="w-full text-idc-blue font-medium mt-1 block pl-4 pr-10 py-3 text-base border border-gray-300 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm rounded-md cursor-pointer"
                      name="shipping_rate"
                      id="shipping_rate"
                      value={shippingRate.hash}
                      onChange={(e) => {
                        const selectedRate =
                          order.available_shipping_rates.find(
                            (rate) => rate.hash === e.target.value
                          )
                        setShippingRate(selectedRate)
                      }}
                    >
                      {order?.available_shipping_rates
                        ? order.available_shipping_rates.map((rate) => (
                            <option
                              key={rate.hash}
                              value={rate.hash}
                            >{`${rate.name} - $${rate.price}`}</option>
                          ))
                        : null}
                    </select>

                    <div className="flex justify-end">
                      <button
                        className="detail-save-button mt-4"
                        type="submit"
                        onClick={async (e) => {
                          e.preventDefault()
                          const result = await updateShippingAddress(
                            order.id,
                            address,
                            shippingRate
                          )
                          if (result.id) {
                            setOrder({
                              ...order,
                              ...result,
                            })
                            setShowEditShipping(!showEditShipping)
                          }
                        }}
                      >
                        Save Changes
                      </button>
                    </div>
                  </form>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>

            <div className="flex justify-between items-baseline mt-[3.3125rem] lg:mt-12 mb-4">
              <p className="font-semibold text-idc-title text-2xl w-[16.25rem] lg:w-[22.875rem]">
                Payment Information
              </p>
              <button
                aria-controls="shipping-and-delivery"
                onClick={() => setShowEditPayment(!showEditPayment)}
                className="selection-link ml"
              >{`${showEditPayment ? "Cancel" : "Edit"}`}</button>
            </div>
            <Tab.Group
              key={`payment-information-${showEditPayment}-${order.id}`}
              defaultIndex={showEditPayment ? 1 : 0}
              onChange={(index) => setShowEditPayment(index === 1)}
            >
              <Tab.List className="hidden">
                <Tab>Display Payment Information</Tab>
                <Tab>Change Payment Information</Tab>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel>
                  <p className="font-semibold text-base mb-[10px] text-idc-title">
                    Credit Card
                  </p>
                  <PaymentDetailsPlaceholder loading={paymentLoading}>
                    <p>XXXX XXXX XXXX {order?.credit_card?.last_four}</p>
                    <p>{parseDate(order?.credit_card?.expiry_date, "short")}</p>
                    <p className="smallNote text-idc-blue text-sm">
                      Please note that the credit card details can not be
                      updated online, Kindly{" "}
                      <a href="tel:1-800-425-5405">call us</a> to change your
                      payment details
                    </p>
                  </PaymentDetailsPlaceholder>

                  <p className="font-semibold text-base mb-[10px] mt-6 text-idc-title">
                    Billing Address
                  </p>
                  <p>
                    {order.billing_first_name} {order.billing_last_name}
                  </p>
                  <p>{order.billing_address1}</p>
                  <p>{order.billing_address2}</p>
                  <p>
                    {order.billing_city}, {order.billing_zip}
                  </p>
                  <p>{order.billing_country}</p>
                </Tab.Panel>
                <Tab.Panel>
                  <form className="bg-white pb-6 space-y-6 text-idc-blue border-b">
                    <p className="font-semibold text-base text-idc-title mb-2">
                      Billing Address
                    </p>

                    <label
                      htmlFor="billingMatchesShipping"
                      className="font-medium text-idc-body flex items-center cursor-pointer"
                    >
                      <div className="relative mr-2 p-[0.125rem] h-[1.25rem] w-[1.25rem] border border-gray-300 rounded-sm">
                        <input
                          checked={billingMatchesShipping}
                          id="billingMatchesShipping"
                          type="checkbox"
                          name="billingMatchesShipping"
                          className="peer sr-only cursor-pointer"
                          onChange={handleBillingMatchesShippingChange}
                        />

                        <div
                          className={`peer-focus-visible:ring-idc-blue peer-focus-visible:ring-offset-2 peer-focus-visible:ring-2 cursor-pointer w-full h-full rounded-sm ${
                            billingMatchesShipping ? "bg-idc-orange" : ""
                          }`}
                        ></div>
                      </div>
                      My billing and shipping address are the same
                    </label>

                    <div
                      className={`${
                        billingMatchesShipping ? "" : "hidden"
                      } text-idc-body mt-2`}
                    >
                      <p>
                        {order.first_name} {order.last_name}
                      </p>
                      <p>{order.address1}</p>
                      <p>{order.address2}</p>
                      <p>
                        {order.city}, {order.zip}
                      </p>
                      <p>{order.country}</p>
                    </div>

                    <div
                      className={`${billingMatchesShipping ? "hidden" : ""}`}
                    >
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="billing_first_name"
                            className="detail-input-label"
                          >
                            First Name
                          </label>
                          <input
                            value={billingAddress.billing_first_name}
                            id="billing_first_name"
                            type="text"
                            name="billing_first_name"
                            autoComplete="given-name"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm"
                            onChange={(e) => handleInputChange(e, true)}
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="billing_last_name"
                            className="detail-input-label"
                          >
                            Last Name
                          </label>
                          <input
                            value={billingAddress.billing_last_name}
                            id="billing_last_name"
                            type="text"
                            name="billing_last_name"
                            autoComplete="family-name"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm"
                            onChange={(e) => handleInputChange(e, true)}
                          />
                        </div>

                        <div className="col-span-6">
                          <label
                            htmlFor="billing_address1"
                            className="detail-input-label"
                          >
                            Address
                          </label>
                          <input
                            value={billingAddress.billing_address1}
                            id="billing_address1"
                            type="text"
                            name="billing_address1"
                            autoComplete="street-address"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm"
                            onChange={(e) => handleInputChange(e, true)}
                            required
                          />
                        </div>

                        <div className="col-span-6">
                          <label
                            htmlFor="billing_address2"
                            className="detail-input-label"
                          >
                            Appartment/Unit Number{" "}
                            <span className="text-xs">(optional)</span>
                          </label>
                          <input
                            value={billingAddress.billing_address2}
                            id="billing_address2"
                            type="text"
                            name="billing_address2"
                            autoComplete="off"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm"
                            onChange={(e) => handleInputChange(e, true)}
                          />
                        </div>

                        <div className="col-span-6">
                          <label
                            htmlFor="billing_company"
                            className="detail-input-label"
                          >
                            Company
                          </label>
                          <input
                            value={billingAddress.billing_company}
                            id="billing_company"
                            type="text"
                            name="billing_company"
                            autoComplete="organization"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm"
                            onChange={(e) => handleInputChange(e, true)}
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="billing_country"
                            className="detail-input-label"
                          >
                            Country
                          </label>
                          <CountryDropdown
                            classes="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm"
                            id="billing_country"
                            name="billing_country"
                            showDefaultOption={false}
                            value={billingAddress.billing_country}
                            onChange={(val) =>
                              handleSelectChange(val, "billing_country", true)
                            }
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="billing_province"
                            className="detail-input-label"
                          >
                            Province
                          </label>
                          <RegionDropdown
                            id="billing_province"
                            name="billing_province"
                            country={billingAddress.billing_country}
                            value={billingAddress.billing_province}
                            classes="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm"
                            onChange={(val) =>
                              handleSelectChange(val, "billing_province", true)
                            }
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="billing_city"
                            className="detail-input-label"
                          >
                            City
                          </label>
                          <input
                            value={billingAddress.billing_city}
                            id="billing_city"
                            type="text"
                            name="billing_city"
                            autoComplete="address-level2"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm"
                            onChange={(e) => handleInputChange(e, true)}
                            required
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="billing_zip"
                            className="detail-input-label"
                          >
                            Postal Code
                          </label>
                          <input
                            value={billingAddress.billing_zip}
                            id="billing_zip"
                            type="text"
                            name="billing_zip"
                            autoComplete="postal-code"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm"
                            onChange={(e) => handleInputChange(e, true)}
                          />
                        </div>

                        <div className="col-span-6">
                          <label
                            htmlFor="billing_phone"
                            className="detail-input-label"
                          >
                            Phone Number
                          </label>
                          <input
                            value={billingAddress.billing_phone}
                            id="billing_phone"
                            type="text"
                            name="billing_phone"
                            autoComplete="tel"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue sm:text-sm"
                            onChange={(e) => handleInputChange(e, true)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-end">
                      <button
                        className="detail-save-button mb-4"
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault()
                          handleBillingAddressUpdate()
                        }}
                      >
                        Save Changes
                      </button>
                    </div>
                  </form>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>

            <p className="font-semibold text-idc-title text-2xl w-[16.25rem] lg:w-[22.875rem] mt-12">
              Promotions
            </p>
            {order.discount ? (
              <>
                <p className="text-idc-title font-semibold text-[0.9375rem] mb-1">
                  Current Discount
                </p>
                <p className="text-idc-body text-[0.8125rem]">
                  {order.discount.discount_code} -{" "}
                  {order.discount.discount_details}
                </p>
              </>
            ) : (
              <p className="text-idc-body text-[0.8125rem]">
                This order has no discounts applied
              </p>
            )}

            <p className="font-semibold text-idc-title text-2xl mt-[3.3125rem] lg:mt-12 mb-4">
              Cancel Subscription
            </p>
            <p className="text-idc-body mb-4">{`Once your subscription order is cancelled you will no longer be subscribed to ${orderProduct?.product_title} subscription - #${order.id}`}</p>
            <button
              onClick={async () => {
                const result = await cancelSubscription(order.id)
                if (result.status === 200) {
                  //show a popup saying subscription has been cancelled
                  alert("Subscription has been cancelled")
                } else {
                  alert(
                    "Please refresh the page to confirm if your subscription has been cancelled"
                  )
                }
              }}
              className="border-2 border-idc-blue font-bold text-sm py-4 px-10 text-idc-blue rounded-full hover:bg-idc-light-blue active:bg-idc-light-blue transition duration-300 ease-in-out"
            >
              Cancel Subscription
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default SubscriptionDetail
