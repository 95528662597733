import React, { FC } from "react"
import { navigate } from "gatsby"
import { isLoggedIn } from "services/auth"

export interface PathProps {
  path: string
  component: React.FunctionComponent
}

const PrivateRoute: FC<PathProps> = ({
  component: Component,
  location,
  ...rest
}) => {
  const isBrowser = typeof window !== "undefined"
  if (isBrowser && !isLoggedIn() && location.pathname !== `/account/login`) {
    navigate("/account/login")
    return null
  }
  return <Component {...rest} />
}
export default PrivateRoute
