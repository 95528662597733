import React from "react"
import { Menu, Transition } from "@headlessui/react"
import { Fragment } from "react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { Link } from "gatsby"

const AccountMenu = ({ locationProp, handleLogout }) => {
  const pathName = locationProp.pathname

  let menuTag
  switch (true) {
    case pathName.startsWith("/account/orders"):
      menuTag = "Order History"
      break

    default:
      menuTag = "Account"
      break
  }

  return (
    <Menu
      as="div"
      className="relative lg:hidden w-full inline-block text-left text-idc-body text-[0.9375rem]"
    >
      <div>
        <Menu.Button className="flex justify-between items-center py-4 w-full font-semibold border-b text-idc-blue focus:outline-none focus-visible:border-b-idc-blue">
          <p className="sr-only">Account Menu</p>
          <p aria-hidden>{menuTag}</p>
          <ChevronDownIcon className="ml-2 w-5 h-5" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="grid absolute right-0 z-10 w-full bg-white rounded-b-md divide-y shadow-lg divide-idc-border focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <Link
                activeClassName="bg-idc-light-blue text-idc-blue font-bold"
                partiallyActive={true}
                to="/account/orders/"
                className={`px-4 py-3 hover:bg-idc-light hover:text-idc-blue ${
                  (active && "bg-idc-light text-idc-blue") || ""
                }transition duration-300 ease-in-out  }`}
              >
                Orders
              </Link>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <Link
                activeClassName="bg-idc-light-blue text-idc-blue font-bold"
                to="/account/manage-subscriptions/"
                className={`px-4 py-3 hover:bg-idc-light hover:text-idc-blue ${
                  (active && "bg-idc-light text-idc-blue") || ""
                }transition duration-300 ease-in-out  }`}
              >
                Manage Subscriptions
              </Link>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <Link
                activeClassName="bg-idc-light-blue text-idc-blue font-bold"
                to="/account/loyalty-rewards/"
                className={`px-4 py-3 hover:bg-idc-light hover:text-idc-blue ${
                  (active && "bg-idc-light text-idc-blue") || ""
                }transition duration-300 ease-in-out  }`}
              >
                Rewards
              </Link>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={handleLogout}
                className={`text-left rounded-b-md px-4 py-3 hover:bg-idc-light hover:text-idc-blue ${
                  (active && "bg-idc-light text-idc-blue") || ""
                }transition duration-300 ease-in-out  }`}
              >
                Logout
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default AccountMenu
