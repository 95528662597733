import React, { FC, useState } from "react"
import { useMutation } from "urql"
import { isLoggedIn } from "services/auth"
import { navigate, Link } from "gatsby"
import { NotificationContext } from "context/notification-context"
import { FormContainer } from "components/elements/Container"

interface Customer {
  email: string
  password: string
  firstName: string
  lastName: string
  acceptsMarketing: boolean
}

interface CreateAccountProps {
  path: string
}

const CreateAccountQuery = `
  mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {   
      customerUserErrors { 
        code
        field
        message
      }
      customer {
        id
      }
    }
  }
`

const CreateAccount: FC<CreateAccountProps> = () => {
  const [customer, setCustomer]: [Customer, any] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    acceptsMarketing: false,
  })
  const [confirmPassword, setConfirmPassword]: [string, any] = useState("")
  const [errors, setErrors]: [object[], any] = useState({})
  const [isLoading, setIsLoading]: [boolean, any] = useState(false)
  const { sendNotification } = React.useContext(NotificationContext)

  const handleInputChange = (e) => {
    setErrors({})
    setCustomer({
      ...customer,
      [e.target.name]: e.target.value,
    })
  }

  const handleCheckboxChange = () => {
    setCustomer({
      ...customer,
      acceptsMarketing: !customer.acceptsMarketing,
    })
  }

  const [, createAccount] = useMutation(CreateAccountQuery)

  const handleSubmit = (e) => {
    e.preventDefault()
    const formValid = validateForm()
    setIsLoading(true)

    if (formValid) {
      createAccount({ input: customer }).then((result) => {
        const { data, error } = result
        if (data?.customerCreate?.customer) {
          sendNotification({
            tpye: "success",
            messages: ["Your account has been created successfully!"],
          })
          navigate("/account/login/")
        }
        if (data?.customerCreate?.customerUserErrors.length > 0) {
          sendNotification({
            type: "error",
            messages: data?.customerCreate?.customerUserErrors.map(
              (e) => e.message
            ),
          })
        }
        if (error) {
          sendNotification({
            type: "error",
            messages: error.graphQLErrors.map((e) => e.message),
          })
        }
        setIsLoading(false)
      })
    } else {
      setIsLoading(false)
    }
  }

  const validateForm = () => {
    const formErrors = {}
    // password validation
    switch (true) {
      case customer.password.trim() === "" || confirmPassword.trim() === "":
        formErrors.password =
          "Invalid password value. Whitespace passwords not allowed."
        formErrors.confirmPassword =
          "Invalid password value. Whitespace passwords not allowed."
        break
      case customer.password.trim() !== confirmPassword.trim():
        formErrors.password = "Passwords do not match."
        formErrors.confirmPassword = "Passwords do not match."
        break
      default:
        break
    }

    // email validation
    switch (true) {
      case customer.email.trim() === "":
        formErrors.email = "Email is required."
        break
      case !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(customer.email):
        formErrors.email = "Please enter valid email address"
        break
      default:
        break
    }

    // first name validation
    switch (true) {
      case customer.firstName.trim() === "":
        formErrors.firstName = "First name is required."
        break
      default:
        break
    }

    // first name validation
    switch (true) {
      case customer.lastName.trim() === "":
        formErrors.lastName = "Last name is required."
        break
      default:
        break
    }

    setErrors(formErrors)
    return Object.keys(formErrors).length === 0
  }

  const ErrorMessage = ({ error, description = "" }) =>
    error ? (
      <p
        aria-describedby={description}
        className="text-xs text-idc-orange mt-1"
      >
        {error}
      </p>
    ) : null

  if (isLoggedIn()) {
    navigate(`/account/`)
  }

  return (
    <FormContainer>
      <form
        method="post"
        onSubmit={(event) => {
          handleSubmit(event)
        }}
      >
        <div className="">
          <div className="bg-white py-6 space-y-6 text-idc-body">
            <div>
              <h2 className="text-lg leading-6 font-medium text-idc-title">
                Create a new account
              </h2>
              <p className="mt-1 text-sm text-idc-subtle">
                Shopping with us is easy! We save all of your account
                information and shopping history here for you to reference and
                manage whenever you need to. Don’t worry, we never share your
                personal information with anyone else.
              </p>
            </div>

            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium"
                >
                  First Name
                </label>
                <input
                  value={customer.firstName}
                  id="firstName"
                  type="text"
                  name="firstName"
                  autoComplete="given-name"
                  onChange={handleInputChange}
                  className={`${
                    errors?.firstName ? "border-idc-orange" : ""
                  } mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue`}
                  aria-required
                />
                <ErrorMessage
                  error={errors?.firstname}
                  description="firstname"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="lastName" className="block text-sm font-medium">
                  Last Name
                </label>
                <input
                  value={customer.lastName}
                  id="lastName"
                  type="text"
                  name="lastName"
                  autoComplete="family-name"
                  onChange={handleInputChange}
                  className={`${
                    errors?.lastName ? "border-idc-orange" : ""
                  } mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue`}
                  aria-required
                />
                <ErrorMessage error={errors?.lastName} description="lastName" />
              </div>

              <div className="col-span-6">
                <label htmlFor="email">Email</label>
                <input
                  value={customer.email}
                  id="email"
                  type="email"
                  name="email"
                  autoComplete="email"
                  onChange={handleInputChange}
                  className={`${
                    errors?.email ? "border-idc-orange" : ""
                  } mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue`}
                  aria-required
                />
                <ErrorMessage error={errors?.email} description="email" />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="password">Password</label>
                <input
                  value={customer.password}
                  id="password"
                  type="password"
                  name="password"
                  autoComplete="new-password"
                  onChange={handleInputChange}
                  className={`${
                    errors?.password ? "border-idc-orange" : ""
                  } mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue`}
                  aria-required
                />
                <ErrorMessage error={errors?.password} description="password" />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                  id="confirmPassword"
                  type="password"
                  name="confirmPassword"
                  autoComplete="off"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value)
                  }}
                  className={`${
                    errors?.confirmPassword ? "border-idc-orange" : ""
                  } mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue`}
                />
                <ErrorMessage
                  error={errors?.confirmPassword}
                  description="confirmPassword"
                />
              </div>
            </div>

            <label
              htmlFor="acceptsMarketing"
              className="font-medium text-idc-body text-sm flex items-center cursor-pointer"
            >
              <div className="relative mr-2 p-[0.125rem] h-[1.25rem] w-[1.25rem] border border-gray-300 rounded-sm">
                <input
                  checked={customer.acceptsMarketing}
                  id="acceptsMarketing"
                  type="checkbox"
                  name="acceptsMarketing"
                  className="peer sr-only cursor-pointer"
                  onChange={handleCheckboxChange}
                />
                <div
                  className={`peer-focus-visible:ring-idc-blue peer-focus-visible:ring-offset-2 peer-focus-visible:ring-2 cursor-pointer w-full h-full rounded-sm ${
                    customer.acceptsMarketing ? "bg-idc-orange" : ""
                  }`}
                ></div>
              </div>
              Sign up for our newsletter
            </label>

            <div className="py-3">
              <button
                className="mt-2 w-full inline-flex justify-center rounded-full px-4 py-3 text-base font-bold text-idc-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-idc-blue sm:text-sm bg-idc-light-blue bg-opacity-50 hover:bg-opacity-75 active:bg-opacity-100 transition duration-300 ease-in-out"
                type="submit"
                aria-label="create account"
                disabled={isLoading}
              >
                Create account
              </button>
            </div>
          </div>
        </div>
      </form>
      <p className="text-center">
        Already have an account?{" "}
        <Link
          to="/account/login"
          className="font-semibold text-idc-blue underline hover:text-[#F47761] active:text-idc-orange transition duration-300 ease-in-out"
        >
          Login
        </Link>
      </p>
    </FormContainer>
  )
}

export default CreateAccount
