import React, { useState, useEffect } from "react"
// import { navigate } from "gatsby";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector"
import { getUser } from "services/auth"
import { NotificationContext } from "context/notification-context"
import { useMutation } from "urql"

interface Address {
  lastName: string
  firstName: string
  company: string
  address1: string
  address2: string
  province: string
  country: string
  zip: string
  city: string
  phone: string
}

const CreateAddressMutation = `
  mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
    customerAddressCreate(customerAccessToken: $customerAccessToken, address: $address) {
      customerUserErrors {
        code
        field
        message
      }
      customerAddress {
        id
      }
    }
  }
`

const UpdateAddressMutation = `
  mutation customerAddressUpdate($customerAccessToken: String!, $address: MailingAddressInput!, $id: ID!) {
    customerAddressUpdate(customerAccessToken: $customerAccessToken, address: $address, id: $id) {
      customerUserErrors {
        code
        field
        message
      }
      customerAddress {
        id
      }
    }
  }
`

const UpdateDefaultAddressQuery = `
  mutation customerDefaultAddressUpdate($customerAccessToken: String!, $addressId: ID!) {
    customerDefaultAddressUpdate(
      customerAccessToken: $customerAccessToken
      addressId: $addressId
    ) {
      customer {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

const UpdateAddress = ({ addressToUpdate = {}, setShowAddressForm }) => {
  const { sendNotification } = React.useContext(NotificationContext)
  const [errors, setErrors]: [object[], any] = useState({})
  const [isLoading, setIsLoading]: [boolean, any] = useState(false)
  const [defaultAddress, setDefaultAddress]: [boolean, any] = useState(false)
  const addressToUpdateId = addressToUpdate?.id ?? null
  const [address, setAddress]: [Address, any] = useState({
    lastName: "",
    firstName: "",
    address1: "",
    address2: "",
    company: "",
    province: "",
    country: "Canada",
    zip: "",
    city: "",
    phone: "",
  })

  useEffect(() => {
    setAddress({
      lastName: addressToUpdate?.lastName ?? "",
      firstName: addressToUpdate?.firstName ?? "",
      address1: addressToUpdate?.address1 ?? "",
      address2: addressToUpdate?.address2 ?? "",
      company: addressToUpdate?.company ?? "",
      province: addressToUpdate?.province ?? "",
      country: addressToUpdate?.country ?? "Canada",
      zip: addressToUpdate?.zip ?? "",
      city: addressToUpdate?.city ?? "",
      phone: addressToUpdate?.phone ?? "",
    })
    setDefaultAddress(false)
  }, [addressToUpdate])

  const handleInputChange = (e) => {
    setErrors({})
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    })
  }

  const handleSelectChange = (value: string, name: string) => {
    setErrors({})
    setAddress({
      ...address,
      [name]: value,
    })
  }

  const handleCheckboxChange = () => {
    setDefaultAddress(!defaultAddress)
  }

  const [, createAddress] = useMutation(CreateAddressMutation)
  const [, updateAddress] = useMutation(UpdateAddressMutation)
  const [, updateDefaultAddress] = useMutation(UpdateDefaultAddressQuery)
  const handleSubmit = (e) => {
    e.preventDefault()
    const formValid = validateForm()
    setIsLoading(true)
    const user = getUser()

    if (formValid) {
      if (addressToUpdateId) {
        // update an existing address
        updateAddress({
          customerAccessToken: user.accessToken,
          address: address,
          id: addressToUpdateId,
        }).then((result) => {
          const { data, error } = result
          if (data?.customerAddressUpdate?.customerAddress) {
            if (defaultAddress) {
              updateDefaultAddress({
                customerAccessToken: user.accessToken,
                addressId: data?.customerAddressUpdate?.customerAddress?.id,
              })
            }
            sendNotification({
              type: "success",
              messages: ["Your Shipping Address has been saved successfully!"],
            })
          }
          if (data?.customerAddressUpdate?.customerUserErrors.length > 0) {
            sendNotification({
              type: "error",
              messages: data?.customerAddressUpdate?.customerUserErrors.map(
                (e) => e.message
              ),
            })
          }
          if (error) {
            sendNotification({
              type: "error",
              messages: error.graphQLErrors.map((e) => e.message),
            })
          }
          setIsLoading(false)
        })
      } else {
        // create a new address
        createAddress({
          customerAccessToken: user.accessToken,
          address: address,
        }).then((result) => {
          const { data, error } = result
          if (data?.customerAddressCreate?.customerAddress) {
            if (defaultAddress) {
              updateDefaultAddress({
                customerAccessToken: user.accessToken,
                addressId: data?.customerAddressCreate?.customerAddress?.id,
              })
            }

            sendNotification({
              type: "success",
              messages: ["Your Shipping Address has been saved successfully!"],
            })
            // navigate("/account/")
          }
          if (data?.customerAddressCreate?.customerUserErrors.length > 0) {
            sendNotification({
              type: "error",
              messages: data?.customerAddressCreate?.customerUserErrors.map(
                (e) => e.message
              ),
            })
          }
          if (error) {
            sendNotification({
              type: "error",
              messages: error.graphQLErrors.map((e) => e.message),
            })
          }
          setIsLoading(false)
        })
      }
    } else {
      setIsLoading(false)
    }
  }

  const validateForm = () => {
    const formErrors = {}
    // city validation
    switch (true) {
      case address.city.trim() === "":
        formErrors.city = "Invalid City entered. City must not be whitespace"
        break
      default:
        break
    }

    // country validation
    switch (true) {
      case address.country.trim() === "":
        formErrors.country =
          "Invalid Country entered. Country must not be whitespace"
        break
      default:
        break
    }

    // province validation
    switch (true) {
      case address.province.trim() === "":
        formErrors.province =
          "Invalid State/Provinve entered. State/Provinve must not be whitespace"
        break
      default:
        break
    }

    // address1 validation
    switch (true) {
      case address.address1.trim() === "":
        formErrors.address1 =
          "Invalid Street Address entered. Street Address must not be whitespace"
        break
      default:
        break
    }

    setErrors(formErrors)
    return Object.keys(formErrors).length === 0
  }

  const ErrorMessage = ({ error, description = "" }) =>
    error ? (
      <p
        aria-describedby={description}
        className="text-xs text-idc-orange mt-1"
      >
        {error}
      </p>
    ) : null

  return (
    <>
      <form
        method="post"
        onSubmit={(event) => {
          handleSubmit(event)
        }}
      >
        <div className="bg-white pb-6 space-y-6 text-idc-body">
          <div className="grid grid-cols-6 gap-6 lg:max-w-[34.75rem]">
            <div className="col-span-6 sm:col-span-3">
              <label htmlFor="firstName" className="block text-sm font-medium">
                First Name
              </label>
              <input
                value={address.firstName}
                id="firstName"
                type="text"
                name="firstName"
                autoComplete="given-name"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue"
                onChange={handleInputChange}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label htmlFor="lastName" className="block text-sm font-medium">
                Last Name
              </label>
              <input
                value={address.lastName}
                id="lastName"
                type="text"
                name="lastName"
                autoComplete="family-name"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue"
                onChange={handleInputChange}
              />
            </div>

            <div className="col-span-6">
              <label htmlFor="address1" className="block text-sm font-medium">
                Street Address
              </label>
              <input
                value={address.address1}
                id="address1"
                type="text"
                name="address1"
                autoComplete="street-address"
                className={`${
                  errors?.address1 ? "border-idc-orange" : ""
                } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue`}
                onChange={handleInputChange}
                aria-required
              />
              <ErrorMessage error={errors?.address1} description="address1" />
            </div>

            <div className="col-span-6">
              <label htmlFor="address2" className="block text-sm font-medium">
                Appartment/Unit Number{" "}
                <span className="text-sm font-normal">(optional)</span>
              </label>
              <input
                value={address.address2}
                id="address2"
                type="text"
                name="address2"
                autoComplete="off"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue"
                onChange={handleInputChange}
              />
            </div>

            <div className="col-span-6">
              <label htmlFor="company" className="block text-sm font-medium">
                Company
              </label>
              <input
                value={address.company}
                id="company"
                type="text"
                name="company"
                autoComplete="organization"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue"
                onChange={handleInputChange}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label htmlFor="country" className="block text-sm font-medium">
                Country
              </label>
              <CountryDropdown
                classes={`${
                  errors?.country ? "border-idc-orange" : ""
                } mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue`}
                id="country"
                name="country"
                whitelist={["CA", "US"]}
                showDefaultOption={false}
                value={address.country}
                onChange={(val) => handleSelectChange(val, "country")}
              />
              <ErrorMessage error={errors?.country} description="country" />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label htmlFor="province" className="block text-sm font-medium">
                State/Province
              </label>
              <RegionDropdown
                id="province"
                name="province"
                country={address.country}
                value={address.province}
                classes={`${
                  errors?.province ? "border-idc-orange" : ""
                } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue`}
                onChange={(val) => handleSelectChange(val, "province")}
              />
              <ErrorMessage error={errors?.province} description="province" />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label htmlFor="city" className="block text-sm font-medium">
                City
              </label>
              <input
                value={address.city}
                id="city"
                type="text"
                name="city"
                autoComplete="address-level2"
                className={`${
                  errors?.city ? "border-idc-orange" : ""
                } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue`}
                onChange={handleInputChange}
                aria-required
              />
              <ErrorMessage error={errors?.city} description="city" />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label htmlFor="zip" className="block text-sm font-medium">
                ZIP/Postal Code
              </label>
              <input
                value={address.zip}
                id="zip"
                type="text"
                name="zip"
                autoComplete="postal-code"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue"
                onChange={handleInputChange}
              />
            </div>

            <div className="col-span-6">
              <label htmlFor="phone" className="block text-sm font-medium">
                Phone Number
              </label>
              <input
                value={address.phone}
                id="phone"
                type="text"
                name="phone"
                autoComplete="tel"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue"
                onChange={handleInputChange}
              />
            </div>
          </div>

          <label
            htmlFor="defaultAddress"
            className="font-medium text-idc-body text-sm flex items-center cursor-pointer"
          >
            <div className="relative mr-2 p-[0.125rem] h-[1.25rem] w-[1.25rem] border border-gray-300 rounded-sm">
              <input
                checked={defaultAddress}
                id="defaultAddress"
                type="checkbox"
                name="defaultAddress"
                className="peer sr-only cursor-pointer"
                onChange={handleCheckboxChange}
              />
              <div
                className={`peer-focus-visible:ring-idc-blue peer-focus-visible:ring-offset-2 peer-focus-visible:ring-2 cursor-pointer w-full h-full rounded-sm ${
                  defaultAddress ? "bg-idc-orange" : ""
                }`}
              ></div>
            </div>
            Make this my primary address
          </label>
        </div>

        <div className="flex items-center pt-3">
          <button
            className="inline-block mr-8 py-3 px-6 w-[10.5rem] text-center font-bold text-large rounded-full transition duration-300 ease-in-out bg-idc-orange text-white hover:bg-idc-blue hover:bg-opacity-75 active:bg-idc-blue"
            type="submit"
            aria-label="update default address"
            disabled={isLoading}
          >
            Save Changes
          </button>

          <button
            type="button"
            onClick={() => setShowAddressForm(false)}
            className="font-semibold text-idc-blue underline hover:text-[#F47761] active:text-idc-orange transition duration-300 ease-in-out"
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  )
}

export default UpdateAddress
