import React, { useState } from "react"
import { navigate, Link } from "gatsby"
import { useMutation } from "urql"
import { isLoggedIn } from "services/auth"
import { NotificationContext } from "context/notification-context"
import { FormContainer } from "components/elements/Container"

const RecoverPasswordQuery = `
mutation customerRecover($email: String!) {
  customerRecover(email: $email) {
    customerUserErrors {
      code
      field
      message
    }
  }
}
`

const RecoverPassword = () => {
  const [customer, setCustomer]: [{ email: string }, any] = useState({
    email: "",
  })
  const [errors, setErrors]: [object[], any] = useState({})
  const { sendNotification } = React.useContext(NotificationContext)

  const handleInputChange = (e) => {
    setErrors({})
    setCustomer({
      ...customer,
      [e.target.name]: e.target.value,
    })
  }

  const [, recoverPassword] = useMutation(RecoverPasswordQuery)

  const handleSubmit = async (e) => {
    //https://formidable.com/open-source/urql/docs/basics/react-preact/#mutations
    e.preventDefault()
    const formValid = validateForm()

    if (formValid) {
      recoverPassword({ email: customer.email }).then((result) => {
        const { data, error } = result
        if (data?.customerRecover?.customerUserErrors.length === 0) {
          sendNotification({
            type: "success",
            messages: ["Password recovery email sent successfully!"],
          })
          navigate("/account/login/")
        } else {
          sendNotification({
            type: "error",
            messages: data?.customerRecover?.customerUserErrors.map(
              (e) => e.message
            ),
          })
        }
        if (error) {
          sendNotification({
            type: "error",
            messages: error.graphQLErrors.map((e) => e.message),
          })
        }
      })
    }
  }

  const validateForm = () => {
    const formErrors = {}

    // email validation
    switch (true) {
      case customer.email.trim() === "":
        formErrors.email =
          "Invalid Email entered. Email must not be whitespace."
        break
      case !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(customer.email):
        formErrors.email = "Please enter valid email address."
        break
      default:
        break
    }

    setErrors(formErrors)
    return Object.keys(formErrors).length === 0
  }

  const ErrorMessage = ({ error, description = "" }) =>
    error ? (
      <p
        aria-describedby={description}
        className="text-xs text-idc-orange mt-1"
      >
        {error}
      </p>
    ) : null

  if (isLoggedIn()) {
    navigate(`/account/`)
  }

  return (
    <FormContainer>
      <form
        method="post"
        onSubmit={(event) => {
          handleSubmit(event)
        }}
      >
        <div className="mx-auto">
          <div className="bg-white py-6 space-y-6 text-idc-body">
            <div>
              <h2 className="text-lg leading-6 font-medium text-idc-title">
                Reset your password
              </h2>
              <p className="mt-1 text-sm text-idc-subtle">
                Recover forgotten passwords.
              </p>
            </div>

            <div className="grid gap-6">
              <div className="">
                <label htmlFor="email" className="block text-sm font-medium">
                  Email
                </label>
                <input
                  value={customer.email}
                  id="email"
                  type="email"
                  name="email"
                  autoComplete="email"
                  className={`${
                    errors?.email ? "border-idc-orange" : ""
                  } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue`}
                  onChange={handleInputChange}
                  aria-required
                />
                <ErrorMessage error={errors?.email} description="email" />
              </div>

              <button
                type="submit"
                aria-label="reset password"
                className="mt-2 w-full inline-flex justify-center rounded-full px-4 py-3 text-base font-bold text-idc-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-idc-blue sm:text-sm bg-idc-light-blue bg-opacity-50 hover:bg-opacity-75 active:bg-opacity-100 transition duration-300 ease-in-out"
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </form>
      <p className="text-center">
        <Link
          to="/account/login"
          className="font-semibold text-idc-blue underline hover:text-[#F47761] active:text-idc-orange transition duration-300 ease-in-out"
        >
          Login
        </Link>
      </p>
    </FormContainer>
  )
}

export default RecoverPassword
