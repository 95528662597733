import { getUser } from "services/auth"
const isBrowser = () => typeof window !== "undefined"

const parseCustomerId = (user) => {
  let customerId = ""
  let buff
  if (user?.id) {
    buff = user.id
    customerId = buff.replace("gid://shopify/Customer/", "")
  }

  return customerId
}

const getBoldToken = async () => {
  const user = getUser()
  let Token = {}

  if (isBrowser()) {
    Token = JSON.parse(sessionStorage.getItem("idcBold"))
  }
  if (
    Token?.token &&
    Token?.expiresAt &&
    new Date(Token.expiresAt) > new Date()
  ) {
    return Token.token
  }

  try {
    const tokenResult = await fetch(`/api/bold/get-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customerAccessToken: user.accessToken,
        customerId: user.id,
      }),
    }).then((res) => res.json())

    if (tokenResult?.token && isBrowser()) {
      const now = new Date()
      sessionStorage.setItem(
        "idcBold",
        JSON.stringify({
          token: tokenResult.token,
          expiresAt: now.setHours(now.getHours() + 23),
        })
      )

      return tokenResult.token
    }
  } catch (error) {}

  return ""
}

export const getInitialData = async () => {
  const boldToken = await getBoldToken()
  const user = getUser()

  const customerId = parseCustomerId(user)

  try {
    const initialDataResult = await fetch(`/api/bold/initial-data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        boldToken,
        customerId,
      }),
    }).then((res) => res.json())

    return initialDataResult
  } catch (error) {}
}

export const toggleSubscriptionStatus = async (orderId, orderPaused) => {
  const boldToken = await getBoldToken()
  const user = getUser()
  const customerId = parseCustomerId(user)

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      boldToken,
      customerId,
      orderId,
    }),
  }

  let url = ""
  if (!orderPaused) {
    // pause subscription
    url = `/api/bold/pause-subscription`
  } else {
    // resume subcription
    url = `/api/bold/resume-subscription`
  }

  try {
    const result = await fetch(url, options).then((res) => res.json())
    return result
  } catch (error) {}
}

export const toggleOrderStatus = async (orderId, orderStatus, orderDate) => {
  const boldToken = await getBoldToken()
  const user = getUser()
  const customerId = parseCustomerId(user)

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      boldToken,
      customerId,
      orderId,
      orderDate,
    }),
  }

  let url = ""
  if (orderStatus) {
    // pause subscription
    url = `/api/bold/skip-order`
  } else {
    // resume subcription
    url = `/api/bold/recover-order`
  }

  try {
    const result = await fetch(url, options).then((res) => res.json())
    return result
  } catch (error) {}
}

export const updateFrequency = async (orderId, frequencyNum, frequencyType) => {
  const boldToken = await getBoldToken()
  const user = getUser()
  const customerId = parseCustomerId(user)

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      boldToken,
      customerId,
      orderId,
      frequencyNum,
      frequencyType,
    }),
  }

  try {
    const result = await fetch("/api/bold/update-frequency", options).then(
      (res) => res.json()
    )
    return result
  } catch (error) {}
}

export const updateOrderDate = async (orderId, orderDate) => {
  const boldToken = await getBoldToken()
  const user = getUser()
  const customerId = parseCustomerId(user)

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      boldToken,
      customerId,
      orderId,
      orderDate,
    }),
  }

  try {
    const result = await fetch("/api/bold/update-order-date", options).then(
      (res) => res.json()
    )
    return result
  } catch (error) {}
}

export const getShippingRates = async (orderId) => {
  const boldToken = await getBoldToken()
  const user = getUser()
  const customerId = parseCustomerId(user)

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      boldToken,
      customerId,
      orderId,
    }),
  }

  try {
    const result = await fetch("/api/bold/get-shipping-rates", options).then(
      (res) => res.json()
    )
    return result
  } catch (error) {}
}

export const getCardDetails = async (orderId) => {
  const boldToken = await getBoldToken()
  const user = getUser()
  const customerId = parseCustomerId(user)

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      boldToken,
      customerId,
      orderId,
    }),
  }

  try {
    const result = await fetch("/api/bold/get-card-details", options).then(
      (res) => res.json()
    )
    return result
  } catch (error) {}
}

export const updateShippingAddress = async (
  orderId,
  shippingAddress,
  orderShippingRate
) => {
  const boldToken = await getBoldToken()
  const user = getUser()
  const customerId = parseCustomerId(user)

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      boldToken,
      customerId,
      orderId,
      shippingAddress,
      orderShippingRate,
    }),
  }

  try {
    const result = await fetch(
      "/api/bold/update-shipping-address",
      options
    ).then((res) => res.json())
    return result
  } catch (error) {}
}

export const updateBillingAddress = async (orderId, billingAddress) => {
  const boldToken = await getBoldToken()
  const user = getUser()
  const customerId = parseCustomerId(user)

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      boldToken,
      customerId,
      orderId,
      billingAddress,
    }),
  }

  try {
    const result = await fetch(
      "/api/bold/update-billing-address",
      options
    ).then((res) => res.json())
    return result
  } catch (error) {}
}

export const cancelSubscription = async (orderId, cancelReason = "") => {
  const boldToken = await getBoldToken()
  const user = getUser()
  const customerId = parseCustomerId(user)

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      boldToken,
      customerId,
      orderId,
      cancelReason,
    }),
  }

  try {
    const result = await fetch("/api/bold/cancel-subscription", options).then(
      (res) => res.json()
    )
    return result
  } catch (error) {}
}

export const updateBoxChoices = async (orderId, orderDate, choices) => {
  const boldToken = await getBoldToken()
  const user = getUser()
  const customerId = parseCustomerId(user)

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      boldToken,
      customerId,
      orderId,
      orderDate,
      choices,
    }),
  }

  try {
    const result = await fetch("/api/bold/update-box-choices", options).then(
      (res) => res.json()
    )
    return result
  } catch (error) {
    console.warn(error)
  }
}
