import React, { FC, useState, useEffect } from "react"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "services/auth"
import { StoreContext } from "context/store-context"
import { Link } from "gatsby"
import qs from "qs"
import { NotificationContext } from "context/notification-context"
import { FormContainer } from "components/elements/Container"

interface LoginProps {
  path: string
  callback?: any
}

const Login: FC<LoginProps> = ({ callback = null, locationProp }) => {
  const searchString = locationProp?.search ?? ""
  const queries = qs.parse(searchString.replace("?", ""))

  const [user, setUser]: [{ email: string; password: string }, any] = useState({
    email: "",
    password: "",
  })
  const [errors, setErrors]: [any, any] = useState({})
  const [isLoading, setIsLoading]: [boolean, any] = useState(false)
  const { updateShippingAddress } = React.useContext(StoreContext)
  const { sendNotification } = React.useContext(NotificationContext)

  const handleInputChange = (e) => {
    setErrors({})
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formValid = validateForm()
    setIsLoading(true)

    if (formValid) {
      let account = await handleLogin(user)

      if (account?.customerAccessTokenCreate?.customerAccessToken) {
        setUser({ email: "", password: "" })
        updateShippingAddress()
      }

      if (account?.customerAccessTokenCreate?.customerUserErrors.length > 0) {
        window.scrollTo(0, 0)

        sendNotification({
          type: "error",
          messages: account.customerAccessTokenCreate.customerUserErrors.map(
            (e) => {
              if (e.code === "UNIDENTIFIED_CUSTOMER") {
                return "Username or Password were not recognized. Please check your entries and try again. If you’re still unable to login, click on the forgot password link below."
              } else {
                return e.message
              }
            }
          ),
        })
      }
    } else {
    }
    setIsLoading(false)
  }

  const validateForm = () => {
    const formErrors = {}
    // email validation
    switch (true) {
      case user.email.trim() === "":
        formErrors.email = "Email is required."
        break
      case !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(user.email):
        formErrors.email = "Please enter valid email address"
        break
      default:
        break
    }

    // password validation
    switch (true) {
      case user.password.trim() === "":
        formErrors.password = "Password is required."
        break
      default:
        break
    }

    setErrors(formErrors)
    return Object.keys(formErrors).length === 0
  }

  useEffect(() => {
    if (isLoggedIn()) {
      if (callback) {
        callback(false)
      } else {
        const navUrl = queries?.checkout_url
          ? queries.checkout_url.replace("http://localhost:8000", "")
          : "/account/"
        navigate(navUrl)
      }
    }
  }, [user])

  const ErrorMessage = ({ error, description = "" }) =>
    error ? (
      <p
        aria-describedby={description}
        className="text-xs text-idc-orange mt-1"
      >
        {error}
      </p>
    ) : null

  return (
    <FormContainer>
      <form
        method="post"
        onSubmit={(event) => {
          handleSubmit(event)
        }}
      >
        <div className="mx-auto">
          <div className="bg-white py-6 space-y-6 text-idc-body">
            <div>
              <h2 className="text-lg leading-6 font-medium text-idc-title">
                Login to your account
              </h2>
              <p className="mt-1 text-sm text-idc-subtle">
                Manage shipping addresses and view your order history.
              </p>
            </div>

            <div className="grid gap-6">
              <div className="">
                <label htmlFor="email" className="block text-sm font-medium">
                  Email
                </label>
                <input
                  value={user.email}
                  id="email"
                  type="email"
                  name="email"
                  autoComplete="email"
                  className={`${
                    errors?.email ? "border-idc-orange" : ""
                  } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue`}
                  onChange={handleInputChange}
                  aria-required
                />
                <ErrorMessage error={errors?.email} description="email" />
              </div>

              <div className="">
                <label htmlFor="password" className="block text-sm font-medium">
                  Password
                </label>
                <input
                  value={user.password}
                  id="password"
                  type="password"
                  name="password"
                  autoComplete="password"
                  className={`${
                    errors?.password ? "border-idc-orange" : ""
                  } mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-idc-blue focus:border-idc-blue text-sm lg:text-base font-medium text-idc-blue`}
                  onChange={handleInputChange}
                  aria-required
                />
                <ErrorMessage error={errors?.password} description="password" />
              </div>

              <button
                disabled={isLoading}
                type="submit"
                aria-label="log in"
                className="mt-2 w-full inline-flex justify-center rounded-full px-4 py-3 text-base font-bold text-idc-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-idc-blue sm:w-auto sm:text-sm bg-idc-light-blue bg-opacity-50 hover:bg-opacity-75 active:bg-opacity-100 transition duration-300 ease-in-out"
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </form>
      <p className="text-center">
        Please login using your idrinkcoffee.com credentials to access the
        legacy subscription management interface.
      </p>
      <p className="text-center"></p>
    </FormContainer>
  )
}

export default Login
