import React, { useEffect } from "react"
import { loadScript, CustomerIdentificationDiv } from "utils/yotpoLoyalty"

const LoyaltyPoints = () => {
  useEffect(() => {
    loadScript()
  }, [])

  return (
    <>
      <CustomerIdentificationDiv />
      <div key="yotpo-widget" className="-mx-4 -md:mx-6 -lg:mx-10">
        <div
          className="yotpo-widget-instance"
          data-yotpo-instance-id="103034"
        ></div>
      </div>
    </>
  )
}

export default LoyaltyPoints
