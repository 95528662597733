import React from "react"
import { formatPrice } from "utils/format-price"
import { Link } from "gatsby"

const OrderDetail = ({ order }) => {
  const date = new Date(order.processedAt)
  const formattedDate = date.toLocaleDateString("en-CA", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })
  const lineItems = order.lineItems.edges

  const discountTotal = {
    amount: 0,
    currencyCode: "",
  }

  order.discountApplications.edges.forEach(({ node: discount }) => {
    discountTotal.amount += parseFloat(discount.value.amount)
    discountTotal.currencyCode = discount.value.currencyCode
  })

  return (
    <div className="text-idc-body">
      <div className="flex flex-col lg:flex-row gap-y-4 lg:justify-between mb-4">
        <h1 className="text-idc-title font-semibold lg:font-bold text-2xl lg:text-[1.625rem]">
          Order {order.name}
        </h1>
        <p className="text-[1.0625rem]">
          Order Placed <span className="font-semibold">{formattedDate}</span>
        </p>
      </div>
      <p className="max-w-[40rem] mb-8">{`Thank you for ordering from iDrinkCoffee. ${
        order.fulfillmentStatus !== "FULFILLED"
          ? "Your order will be with you soon."
          : ""
      }`}</p>

      {lineItems.map(({ node: lineItem }, index) => {
        const image = lineItem?.variant?.image?.src ?? ""
        const slug = `/products/${lineItem?.variant?.product?.handle}`
        const price = lineItem?.discountedTotalPrice
        const compareAtPrice =
          lineItem?.originalTotalPrice.amount -
            lineItem?.discountedTotalPrice.amount !==
          0
            ? lineItem?.originalTotalPrice
            : null

        return (
          <Link
            to={slug}
            key={`${lineItem?.title}-${index}`}
            className={`flex py-5 lg:py-12 border-t ${
              index === lineItems?.length - 1 ? "border-b" : ""
            }`}
          >
            <div className="max-w-[15.125rem] h-[7.5rem] lg:h-[8.875rem] mr-8 relative aspect-square shrink-0">
              <img
                className="object-contain object-center w-full h-full"
                src={image}
                alt={lineItem?.title}
              />
            </div>
            <div className="flex flex-col justify-between w-full">
              <div>
                <p className="mb-2 font-medium text-idc-subtle text-[0.9375rem] uppercase">
                  {lineItem?.variant?.sku}
                </p>
                <div className="flex flex-col lg:flex-row lg:justify-between mb-2 lg:mb-0">
                  <p className="text-idc-title text-lg font-bold mb-3 lg:mb-0">
                    {lineItem?.title}
                  </p>
                  <div className="flex lg:flex-col">
                    <p className="font-medium text-idc-title">
                      {formatPrice(price.currencyCode, price.amount)}
                    </p>
                    {compareAtPrice && (
                      <p className="ml-3 text-sm line-through text-idc-subtle">
                        {formatPrice(
                          compareAtPrice.currencyCode,
                          compareAtPrice.amount
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <p className="font-medium text-idc-subtle text-[0.9375rem]">
                Qty: {lineItem?.currentQuantity}
              </p>
            </div>
          </Link>
        )
      })}

      <div className="mt-8 w-full flex flex-col items-end">
        <div className="pb-8 w-full text-[0.8125rem] lg:text-[1.0625rem] lg:w-[18.75rem]">
          <h4 className="font-semibold text-[0.9375rem] lg:text-base text-idc-title mb-2">
            Shipping Address
          </h4>
          <p>{order.shippingAddress.name}</p>
          <p>{order.shippingAddress.address1}</p>
          <p>
            {order.shippingAddress.city}, {order.shippingAddress.provinceCode}{" "}
            {order.shippingAddress.zip}
          </p>
          <p>{order.shippingAddress.country}</p>
        </div>

        <table className="table-auto text-left text-idc-title text-[0.8125rem] lg:text-[1.0625rem] w-full lg:w-[40rem]">
          <caption className="text-left font-bold text-lg mb-4 pt-8 border-t">
            Order Summary
          </caption>
          <thead className="sr-only">
            <tr>
              <th>Title</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="pb-4">Item Subtotal:</td>
              <td className="pb-4 text-right font-medium">
                {formatPrice(
                  order.currentSubtotalPrice.currencyCode,
                  order.currentSubtotalPrice.amount
                )}
              </td>
            </tr>
            {discountTotal.amount > 0 && (
              <tr>
                <td className="pb-4">Discount:</td>
                <td className="pb-4 text-right font-medium">
                  -{" "}
                  {formatPrice(
                    discountTotal.currencyCode,
                    discountTotal.amount
                  )}
                </td>
              </tr>
            )}
            <tr>
              <td className="pb-4">Shipping:</td>
              <td className="pb-4 text-right font-medium">
                {formatPrice(
                  order.totalShippingPriceV2.currencyCode,
                  order.totalShippingPriceV2.amount
                )}
              </td>
            </tr>
            <tr>
              <td className="pb-4">Taxes:</td>
              <td className="pb-4 text-right font-medium">
                {formatPrice(
                  order.currentTotalTax.currencyCode,
                  order.currentTotalTax.amount
                )}
              </td>
            </tr>
            <tr className="border-t">
              <td className="pt-6 font-medium">Total:</td>
              <td className="pt-6 font-medium lg:font-bold lg:text-lg text-right">
                {formatPrice(
                  order.currentTotalPrice.currencyCode,
                  order.currentTotalPrice.amount
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default OrderDetail
