import React, { useContext, useEffect, useState } from "react"
import { isLoggedIn } from "services/auth"
import { getInitialData } from "services/bold"
import SubscriptionDetail from "components/elements/SubscriptionDetail"
import SelectChoicesModal from "components/elements/SelectChoicesModal"
import { SubscriptionDetailPlaceholder } from "components/elements/Placeholders"
import { StoreContext } from "context/store-context"
import { logout } from "services/auth"
import { navigate } from "gatsby"

const ManageSubscriptions = () => {
  const { client } = useContext(StoreContext)
  const [isLoading, setIsLoading] = useState(false)
  const [products, setProducts] = useState({})
  const [showSelectChoices, setShowSelectChoice] = useState(false)
  const [targetSubscriptionDetails, setTargetSubscriptionDetails] = useState({
    orderId: "",
    orderDate: "",
    currentChoices: [],
  })
  const [subscriptionData, setSubscriptionData] = useState({
    orders: [],
    groups: [],
    products: [],
    handle_map: {},
    group_map: {},
  })

  const startChoiceSelection = (orderId, orderDate, currentChoices) => {
    setTargetSubscriptionDetails({
      orderId,
      orderDate,
      currentChoices,
    })
    setShowSelectChoice(true)
  }

  useEffect(() => {
    // transfers sessionStorage from one tab to another
    var sessionStorage_transfer = function (event) {
      if (!event) {
        event = window.event
      } // ie suq
      if (!event.newValue) return // do nothing if no value to work with
      if (event.key == "getSessionStorage") {
        // another tab asked for the sessionStorage -> send it
        localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage))
        // the other tab should now have it, so we're done with it.
        localStorage.removeItem("sessionStorage") // <- could do short timeout as well.
      } else if (event.key == "sessionStorage" && !sessionStorage.length) {
        // another tab sent data <- get it
        var data = JSON.parse(event.newValue)
        for (var key in data) {
          sessionStorage.setItem(key, data[key])
        }
      }
    }

    // listen for changes to localStorage
    if (window.addEventListener) {
      window.addEventListener("storage", sessionStorage_transfer, false)
    } else {
      window.attachEvent("onstorage", sessionStorage_transfer)
    }

    // Ask other tabs for session storage (this is ONLY to trigger event)
    if (!sessionStorage.length) {
      localStorage.setItem("getSessionStorage", "idcBold")
      localStorage.removeItem("getSessionStorage", "idcBold")
    }
  }, [])

  useEffect(() => {
    // let boldToken = sessionStorage.getItem("idcBold")
    let isSubscribed = true
    setIsLoading(true)
    const getData = async () => {
      if (isLoggedIn()) {
        const initialData = await getInitialData()

        initialData["handle_map"] = {}
        initialData["group_map"] = {}
        initialData.products.forEach(
          (product) =>
            (initialData.handle_map[product.product_id] = product.handle)
        )
        initialData.groups.forEach(
          (group) => (initialData.group_map[group.id] = group)
        )
        if (isSubscribed) {
          setSubscriptionData(initialData)
          setIsLoading(false)
        }
      }
    }

    getData()

    return () => {
      isSubscribed = false
    }
  }, [])

  useEffect(() => {
    let isSubscribed = true
    const productIds = subscriptionData.products.map(
      (product) => `gid://shopify/Product/${product.product_id}`
    )

    const getProductDetails = async () => {
      const result = await client.product.fetchMultiple(productIds)
      const filteredResults = result.filter((product) => product != null)
      const productDetails = {}
      filteredResults.forEach((result) => {
        productDetails[result.handle] = result
        productDetails[result.id] = result
      })

      if (isSubscribed) {
        setProducts(productDetails)
      }
    }

    getProductDetails()
    return () => {
      isSubscribed = false
    }
  }, [subscriptionData])
  const { initializeCheckout, clearCheckout } = React.useContext(StoreContext)

  const handleLogout = () => {
    logout(() => {
      clearCheckout()
      initializeCheckout()
      navigate("/account/")
    })
  }
  // console.log('subscriptionData.orders', subscriptionData.orders)

  return (
    <>
      <button
        onClick={handleLogout}
        className="text-left px-4 py-3 rounded-md hover:bg-idc-light hover:text-idc-blue transition duration-300 ease-in-out"
      >
        Logout
      </button>
      <SelectChoicesModal
        isVisible={showSelectChoices}
        setIsVisible={setShowSelectChoice}
        productDetails={products}
        targetSubscriptionDetails={targetSubscriptionDetails}
        handleMap={subscriptionData.handle_map}
      />
      <h1 className="font-bold text-idc-title text-[1.75rem] mt-[1.65rem] mb-2 lg:mb-4">
        Manage Subscriptions
      </h1>
      <p className="text-[1.0625rem] text-idc-body max-w-[40rem] mb-10 lg:mb-8">
        Your subscriptions and upcoming orders are listed on this page. To
        update your entire subscription or individual orders, click the "View
        details" link.
      </p>
      <SubscriptionDetailPlaceholder loading={isLoading}>
        <ul className="grid gap-8">
          {subscriptionData.orders.map((order) => (
            <li key={order.id}>
              <SubscriptionDetail
                startChoiceSelection={startChoiceSelection}
                orderObject={order}
                products={products}
                handleMap={subscriptionData.handle_map}
                groupMap={subscriptionData.group_map}
              />
            </li>
          ))}
        </ul>
      </SubscriptionDetailPlaceholder>
    </>
  )
}

export default ManageSubscriptions
